import React, { useState, useEffect } from "react";
import { connect } from "react-redux";
import {
  setopenmain,
  selezionacustomization,
  setvista,
} from "../../state/actions";

import { Link } from "gatsby";

import LanguageSelector from "../LanguageSelector";

import {
  RiOpenArmLine,
  RiSurgicalMaskLine,
  RiShoppingCartLine,
} from "react-icons/ri";

import logo from "../../images/logo.png";

const Header = (props) => {
  const { lingua, cust, custom, showMenu, setOpenMain, vista, setVis } = props;

  const setCustomization = (tipo) => {
    custom(tipo);
  };

  const [active, setActive] = useState("");

  useEffect(() => {
    window.onpopstate = () => {
      setActive("");
      setOpenMain("");
    };
  });

  return (
    <>
      <nav
        className="navbar is-white"
        role="navigation"
        aria-label="main navigation"
        style={{ backgroundColor: "#eaeae8" }}
      >
        <div className="navbar-brand">
          <Link to="/" className="navbar-item">
            <img src={logo} alt="Logo 3d-shirt" />
          </Link>

          <button
            className="navbar-item is-hidden-widescreen"
            onClick={() => setVis("intera")}
            style={{ background: "transparent", border: "none" }}
          >
            <RiOpenArmLine
              style={
                vista === "intera"
                  ? { fontSize: "1.2rem", color: "#363636" }
                  : { fontSize: "1.2rem", color: "#aaa" }
              }
            />
          </button>

          <button className="navbar-item is-hidden-desktop snipcart-checkout has-background-grey-paky">
            <RiShoppingCartLine
              style={{
                fontSize: "1.2rem",
                color: "#363636",
                border: "none",
                marginRight: "0.5rem",
              }}
            />
            <span className="tag is-dark">
              <span
                className="snipcart-items-count"
                style={{ padding: "0 0.5rem" }}
              />
            </span>
          </button>

          <button
            className="button navbar-burger"
            aria-label="menu"
            onClick={() => setActive("is-active")}
          >
            <span aria-hidden="true"></span>
            <span aria-hidden="true"></span>
            <span aria-hidden="true"></span>
          </button>
        </div>

        <div className="navbar-menu">
          <div className="navbar-start is-hidden-desktop-only is-hidden-touch">
            {showMenu && (
              <>
                <button
                  style={{
                    border: "none",
                    fontSize: "1rem",
                    cursor: "pointer",
                  }}
                  className={
                    cust === "tessuti"
                      ? "navbar-item is-active has-background-white"
                      : "navbar-item has-background-grey-paky"
                  }
                  onClick={() => setCustomization("tessuti")}
                >
                  {lingua.navigazione.tessuto}
                </button>

                <button
                  style={{
                    border: "none",
                    fontSize: "1rem",
                    cursor: "pointer",
                  }}
                  className={
                    cust === "personalizzazione"
                      ? "navbar-item is-active has-background-white"
                      : "navbar-item has-background-grey-paky"
                  }
                  onClick={() => setCustomization("personalizzazione")}
                >
                  {lingua.navigazione.personalizzazione}
                </button>

                <button
                  style={{
                    border: "none",
                    fontSize: "1rem",
                    cursor: "pointer",
                  }}
                  className={
                    cust === "iniziali"
                      ? "navbar-item is-active has-background-white"
                      : "navbar-item has-background-grey-paky"
                  }
                  onClick={() => setCustomization("iniziali")}
                >
                  {lingua.navigazione.iniziali.iniziali}
                </button>

                <button
                  style={{
                    border: "none",
                    fontSize: "1rem",
                    cursor: "pointer",
                  }}
                  className={
                    cust === "misure"
                      ? "navbar-item is-active has-background-white"
                      : "navbar-item has-background-grey-paky"
                  }
                  onClick={() => setCustomization("misure")}
                >
                  {lingua.navigazione.misure.misure}
                </button>
              </>
            )}
          </div>

          <div className="navbar-end">
            <div className="navbar-item">
              <div className="buttons">
                {!showMenu && (
                  <Link
                    to="/tessuto00011"
                    className="button is-dark is-radiusless "
                  >
                    {lingua.sito.bottone1}
                  </Link>
                )}

                <Link
                  to="/blog/"
                  className="button has-background-grey-paky"
                  activeClassName="is-active"
                >
                  Blog
                </Link>

                <Link
                  to="/shop/"
                  className="button has-background-grey-paky"
                  activeClassName="is-active"
                >
                  Shop
                </Link>

                <button className="button snipcart-customer-signin login-button has-background-grey-paky">
                  {lingua.header.account}
                </button>
                <button className="button snipcart-checkout has-background-grey-paky">
                  <span style={{ marginRight: "0.5rem" }}>
                    {lingua.header.shopping}
                  </span>
                  <span className="tag is-dark">
                    <span
                      className="snipcart-items-count"
                      style={{ padding: "0 0.5rem" }}
                    />
                  </span>
                </button>
              </div>
            </div>
          </div>
        </div>
      </nav>

      <div className={"quickview is-hidden-widescreen " + active}>
        <header className="quickview-header">
          <button
            className="delete"
            aria-label="Delete"
            onClick={() => setActive("")}
          ></button>
        </header>
        <div className="quickview-body">
          <div className="quickview-block">
            <aside className="section menu">
              <ul className="menu-list">
                <li>
                  <Link
                    to="/"
                    activeStyle={{ color: "#666" }}
                    onClick={() => setActive("")}
                  >
                    Home
                  </Link>
                </li>

                {!showMenu && (
                  <li>
                    <Link to="/tessuto00011" onClick={() => setActive("")}>
                      {lingua.sito.bottone1}
                    </Link>
                  </li>
                )}

                <li>
                  <Link
                    to="/about-us/"
                    activeStyle={{ color: "#666" }}
                    onClick={() => setActive("")}
                  >
                    {lingua.footer.about}
                  </Link>
                </li>
                <li>
                  <Link
                    to="/our-fabrics/"
                    activeStyle={{ color: "#666" }}
                    onClick={() => setActive("")}
                  >
                    {lingua.footer.ourfabrics}
                  </Link>
                </li>
                <li>
                  <Link
                    to="/our-artisans/"
                    activeStyle={{ color: "#666" }}
                    onClick={() => setActive("")}
                  >
                    {lingua.footer.ourartisans}
                  </Link>
                </li>
                <li>
                  <Link
                    to="/fabrics-family/"
                    onClick={() => setActive("")}
                    activeStyle={{ color: "#5d7e9d" }}
                  >
                    {lingua.footer.famiglie}
                  </Link>
                </li>
                <li>
                  <Link
                    to="/sizes-tables/"
                    onClick={() => setActive("")}
                    activeStyle={{ color: "#5d7e9d" }}
                  >
                    {lingua.navigazione.misure.tabellaTaglie}
                  </Link>
                </li>
                <li>
                  <Link
                    to="/blog/"
                    activeStyle={{ color: "#666" }}
                    onClick={() => setActive("")}
                  >
                    Blog
                  </Link>
                </li>

                <li>
                  <Link
                    to="/shop/"
                    activeStyle={{ color: "#666" }}
                    onClick={() => setActive("")}
                  >
                    Shop
                  </Link>
                </li>

                <li>
                  <Link
                    to="/privacy-policy/"
                    activeStyle={{ color: "#666" }}
                    onClick={() => setActive("")}
                  >
                    {lingua.footer.privacy}
                  </Link>
                </li>
                <li>
                  <Link
                    to="/cookie-policy/"
                    activeStyle={{ color: "#666" }}
                    onClick={() => setActive("")}
                  >
                    {lingua.footer.cookie}
                  </Link>
                </li>
                <li>
                  <Link
                    to="/withdrawal/"
                    activeStyle={{ color: "#666" }}
                    onClick={() => setActive("")}
                  >
                    {lingua.footer.recesso}
                  </Link>
                </li>
                <li>
                  <Link
                    to="/terms/"
                    activeStyle={{ color: "#666" }}
                    onClick={() => setActive("")}
                  >
                    {lingua.footer.termini}
                  </Link>
                </li>
                <li>
                  <Link
                    to="/contact/"
                    activeStyle={{ color: "#666" }}
                    onClick={() => setActive("")}
                  >
                    {lingua.footer.contattaci}
                  </Link>
                </li>
                <hr />
                <li>
                  <button className="button snipcart-customer-signin login-button is-dark is-fullwidth">
                    {lingua.header.account}
                  </button>
                </li>
                <hr />
                <li>
                  <LanguageSelector />
                </li>
              </ul>
            </aside>
          </div>
        </div>
      </div>
    </>
  );
};

function mapStateToProps(state) {
  return {
    vista: state.shop.vista,
  };
}

function mapDispatchToProps(dispatch) {
  return {
    custom: (val) => dispatch(selezionacustomization(val)),
    setOpenMain: (val) => dispatch(setopenmain(val)),
    setVis: (val) => dispatch(setvista(val)),
  };
}

export default connect(mapStateToProps, mapDispatchToProps)(Header);
