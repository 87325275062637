import React, { Component } from 'react'
import { connect } from 'react-redux'

import { selezionalingua } from '../../state/actions'

import Italia from '../../images/flags/it.svg'
import Inghilterra from '../../images/flags/gb.svg'
import Spagna from '../../images/flags/es.svg'
import Francia from '../../images/flags/fr.svg'
import Catalogna from '../../images/flags/es-ct.svg'

class LanguageSelector extends Component {

    componentDidMount() {
        let userLang = this.props.filtroShop || navigator.language || navigator.userLanguage;
        if (
            (userLang !== 'it-IT') &&
            (userLang !== 'en-GB') &&
            (userLang !== 'es-CA') &&
            (userLang !== 'es-ES')
        ) {
            userLang = 'en-GB'
        }
        this.props.shop(userLang)
    }

    selectChange(val) {
        this.props.shop(val)
    }

    render() {
        
        return (
           
                <div className="buttons">
                    <button className="button is-text" onClick={this.selectChange.bind(this,'en-GB')} data-tooltip="English" ><img width="30" src={Inghilterra} alt="En" style={this.props.filtroShop === 'en-GB' ? {opacity: '1'} : {opacity: '0.5'}} /></button>
                    <button className="button is-text" onClick={this.selectChange.bind(this,'it-IT')} data-tooltip="Italiano" ><img width="30" src={Italia} alt="It" style={this.props.filtroShop === 'it-IT' ? {opacity: '1'} : {opacity: '0.5'}} /></button>
                    <button className="button is-text" onClick={this.selectChange.bind(this,'es-ES')} data-tooltip="Español" ><img width="30" src={Spagna} alt="Es" style={this.props.filtroShop === 'es-ES' ? {opacity: '1'} : {opacity: '0.5'}} /></button>
                    {/** 
                    <button className="button is-text" onClick={this.selectChange.bind(this,'es-CA')} data-tooltip="Català" ><img width="30" src={Catalogna} alt="Ca" style={this.props.filtroShop === 'es-CA' ? {opacity: '1'} : {opacity: '0.5'}} /></button>
                    */}
                </div>

        )
    }
}

function mapStateToProps(state) {
    return {
        filtroShop: state.shop.lingua
    }
}

function mapDispatchToProps(dispatch) {
    return {
        shop: (val) => dispatch(selezionalingua(val))
    }
}

export default connect(mapStateToProps, mapDispatchToProps)(LanguageSelector)