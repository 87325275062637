export const lingua_it = {
  lang: "it",
  title: "3D SHIRT - Configuratore 3D per camicie su misura",
  description: "Configuratore 3D per camicie su misura",
  personalizza: "Personalizza",
  misure: "Misure",
  tessuto: "Tessuto",
  filtri: "Filtri",
  scegliTessuto: "Scegli il tessuto",
  cercaTessuto: "Cerca tessuto...",
  inOfferta: "In offerta",
  nonDisponibile: "Non disponibile",
  colore: "Colore",
  colori: "Colori",
  tuttiColori: "Tutti i colori",
  bianco: "Bianco",
  celeste: "Celeste",
  altriColori: "Altri colori",
  disegno: "Disegno",
  disegni: "Disegni",
  tuttiDisegni: "Tutti i disegni",
  tintaUnita: "Tinta unita",
  righe: "Righe",
  quadretti: "Quadretti",
  fantasia: "Fantasia",
  materiale: "Materiale",
  materiali: "Materiali",
  tuttiMateriali: "Tutti i materiali",
  cotone: "Cotone",
  lino: "Lino",
  brand: "Brand",
  brands: "Brands",
  tuttiBrands: "Tutti i brands",
  codice: "Codice",
  ordina: "Ordina per prezzo",
  basso: "Dal prezzo più basso",
  alto: "Dal prezzo più alto",
  lato: "Vista laterale",
  front: "Vista frontale",
  retro: "Vista retro",
  piegata: "Vista piegata",
  extra: {
    tutti: "Tutti i prodotti",
    selTaglia: "Seleziona la taglia",
    desc: "Descrizione",
    correlati: "Prodotti correlati",
  },
  bubble: {
    prima: "Clicca qui per tornare alla vista camicia.",
    seconda: "Trascina con il tasto SINISTRO del mouse per ruotare la camicia",
    terza: "Trascina con il tasto DESTRO del mouse per spostare la camicia.",
    quarta: "Muovi la ROTELLINA del mouse per lo zoom.",
    quinta: "OK, CHIUDI",
    sesta: "Trascina con un dito per ruotare la camicia.",
    settima: "Trascina con due dita per spostare la camicia.",
    ottava: "Allarga o stringi con due dita per lo zoom.",
  },
  banner: {
    titolo: "Uso dei Cookie",
    testo1:
      "In questa applicazione, facciamo uso di cookie (o tecnologie similari) per memorizzare informazioni nel tuo computer o dispositivo mobile al fine di migliorare la tua esperienza online. I cookie sono piccoli file di testo che ti consentono di navigare in modo efficiente, ricordare le tue preferenze, aiutarci a comprendere la performance dell'applicazione e migliorare in generale la tua esperienza di utilizzo.",
    testo2:
      "Chiudendo questo messaggio acconsenti all'utilizzo dei cookie in base alle tue (eventuali) preferenze.",
    gestione: "Gestione dei cookie",
    acconsento: "Acconsento",
  },
  sito: {
    titolo: "Sartorie Italiane",
    sottotitolo:
      "Dal connubio fra tradizione e innovazione nasce SartorieItaliane.it, un evoluto portale di vendita online di camicie su misura. Con una veste grafica chiara ed efficace, è possibile acquistare prodotti di alta qualità sartoriale, realizzati interamente su misura, consegnati direttamente a casa tua.",
    bottone1: "Crea la tua camicia",
    titolo2: "Come funziona il configuratore",
    sottotitolo2:
      "Il configuratore di Sartorie Italiane permette un'esperienza di configurazione facile, veloce e coinvolgente.",
    titolo2a: "Ricevi le nostre novità",
    sottotitolo2a:
      "Inserisci la tua mail per rimanere aggiornato su tutti i nostri nuovi prodotti e offerte",
    configuratore: "Configuratore",
    configura: "Configura",
    iscriviti: "Iscriviti",
    titolo3: "Scegli il tessuto",
    sottotitolo3:
      "Seleziona quello che preferisci, tra una vasta gamma di tessuti pregiati Made in Italy.",
    sottotitolo4:
      "Con l’utilizzo di potentissimi software di progettazione 3D per il fashion design, si ottengono immagini realistiche senza eguali.",
    titolo5: "Personalizza la camicia",
    sottotitolo5:
      "Scegli i dettagli per creare la tua camicia unica e verificarne l’effetto in tempo reale.",
    sottotitolo6:
      " Personalizzando il proprio prodotto, l’utente ha la sensazione di comprare qualcosa di speciale e unico, fatto apposta per lui.",
    titolo7: "Inserisci le misure",
    sottotitolo7:
      "Segui i tutorial per riuscire a prendere le tue misure in modo preciso e professionale.",
    titolo8: "La Produzione",
    sottotitolo8:
      "Le nostre camicie sono prodotte esclusivamente in Italia da abili artigiani, in laboratori sartoriali fra i più prestigiosi al mondo. I nostri esperti sarti, veri maestri la cui sapienza ed abilità si tramanda da generazioni, si occupano di tutto ciò che riguarda il confezionamento, assicurando così la massima precisione in ogni fase del processo di realizzazione.",
    titolo9: " Web Marketing",
    sottotitolo9:
      "Regalando un’esperienza di navigazione coinvolgente, il configuratore aiuta a far crescere le vendite e la notorietà del Brand.",
    titolo10: "Caratteristiche del configuratore di Sartorie Italiane",
    sottotitolo10:
      "L'applicazione guida l'utente dalla personalizzazione del prodotto, fino all'acquisto.",
    titolo11: "Personalizzazione Camicia",
    sottotitolo11:
      "Attraverso pochi e semplici step, sarà possibile configurare la camicia in tutti i suoi aspetti, dalla scelta del tessuto e degli elementi che la compongono, fino all'inserimento delle misure.",
    titolo12: "Processo d'Acquisto",
    sottotitolo12:
      "L'applicazione è in grado di gestire tutte le fasi del processo d'acquisto, dalla gestione del carrello, e quindi prodotti e codici sconto, fino alla gestione della spedizione e del pagamento.",
    titolo13: "Creazione Account",
    sottotitolo13:
      "L'applicazione prevede la possibilità di creare un profilo utente personale che permetterà all'utente stesso di memorizzare le proprie misure e i propri acquisti.",
    chisiamo: {
      sotto1:
        "Ci impegniamo per offrire ai nostri clienti la libertà di poter scegliere il proprio stile in assoluta libertà, certi di ricevere sempre un prodotto di altissima qualità.",
      p1: "Sartorie Italiane® è un brand del gruppo Globalapulia Srl.",
      p2:
        "Dal connubio fra tradizione e innovazione nasce SartorieItaliane.it, un evoluto portale di vendita online di camicie su misura. Con una veste grafica chiara ed efficace, è possibile acquistare prodotti di alta qualità sartoriale, realizzati interamente su misura, consegnati direttamente a casa tua. Grazie ad un rapido ed intuitivo sistema di configurazione, l’utente ha la possibilità di creare la propria camicia, personalizzandola fin nei minimi dettagli: dal colletto, al filo dei bottoni fino ai polsini; puoi anche aggiungere le tue iniziali dovunque desideri. Gioca con i tanti abbinamenti possibili.",
      p3:
        "Conserveremo il tuo disegno e il tuo modello nel nostro sistema così potrai, in futuro, riordinare esattamente lo stesso tipo di taglio o tessuto, se lo desideri; potrai, in seguito, indicarci ogni modifica che vorrai apportare, per soddisfare dettagliatamente i tuoi desideri.",
      p4:
        "Miriamo a ottenere la linea e il taglio che meglio si adattano alla tua figura e ai tuoi gusti, mettendo a tua disposizione una ampia gamma di personalizzazioni e la grande esperienza degli artigiani con cui collaboriamo.",
      p5:
        "Tutto ciò è reso possibile dalla collaborazione con prestigiosi laboratori artigianali, espressione dell’eccellenza italiana, dove abilità e passione si tramandano da generazioni.",
    },
    nostritessuti: {
      sotto1:
        "Scegli tra una ampia gamma di stoffe, tutte rigorosamente prodotte dai più prestigiosi marchi del tessile italiano.",
      p1:
        "Per la tua camicia potrai scegliere diversi tessuti, finiture e abbinamenti di colore. La nostra offerta di tessuti e disegni è in continua evoluzione, seguendo i progressi e le nuove tendenze della moda e dell’industria tessile.",
      p2:
        "La storia della Canclini Tessile inizia nel 1925, quando nasce come industria serica nel distretto comasco. Negli anni 60, avviene il primo cambio generazionale: la notevole esperienza acquisita nella lavorazione di un prodotto nobile e di altissimo livello come la seta inducono negli anni sessanta la famiglia ad abbandonare il settore serico e a rivolgersi verso quello cotoniero. Questa scelta risulta positiva, in quanto negli anni successivi, la vendita dei tessuti si estende anche ai mercati d’oltralpe, in Germania e Francia. Alla fine degli anni ottanta, l’attività dell’ azienda riceve nuovo slancio dall’acquisto di macchinari modernissimi. Oggi l’azienda è annoverata tra i marchi di riferimento nel settore dei tessuti di pregio per la camiceria.",
      p3:
        "Cotonificio Albini S.p.A. è un’azienda italiana con sede ad Albino (provincia di Bergamo) che opera dal 1876 nel settore tessile, producendo tessuti per camiceria di alta gamma. Nel 1992 vengono acquisiti due marchi inglesi, “Thomas Mason” e “David & John Anderson”, congiuntamente al loro archivio storico composto da oltre 600 volumi di inestimabile valore. Il processo di espansione è continuato con nuovi siti produttivi e l’ingresso del marchio “Albiate 1830”, acquistato nel 2000 dalla famiglia Caprotti.",
      p4:
        "Creatività, innovazione e sperimentazione: sono queste le coordinate che caratterizzano il viaggio di ricerca di cui Albiate 1830 è il protagonista assoluto. Grazie alla sua identità dinamica e pioneristica è in grado di svelare itinerari nuovi e mete incontaminate per il casualwear contemporaneo, in un percorso di esplorazione che rafforza il legame con le tendenze emergenti della moda, senza perdere mai di vista l’eccezionale tradizione manifatturiera che da quasi due secoli rappresenta il cuore pulsante del brand. Un’essenza che spinge gli Italian Textile Explorers di Albiate 1830 a creare tessuti dall’ineguagliabile stile e qualità. Con le sue basi sportive, le stampe eccentriche, gli originali jacquard e i denim dalle mille sfumature, Albiate 1830 si rivolge a un pubblico giovane e dall’anima ribelle, confermandosi il leader indiscusso del luxury streetwear. Albiate 1830 è molto più di un brand: è espressione di una ricerca creativa unica, in cui materie prime esclusive, iconici dettagli e sperimentazione danno vita a un viaggio di stile inaspettato e sorprendente.",
      p5:
        "Fondato nel Lancashire nel 1796 dall’imprenditore Thomas Mason, l’omonimo marchio fu un punto di riferimento nel mondo della camiceria inglese per l’eleganza e la qualità dei suoi prodotti. Acquisito da Albini Group nel 1992, Thomas Mason reinterpreta il suo ricco heritage in chiave moderna e originale, attraverso tessuti doppi ritorti creati con il raffinato lino di Normandia e con i preziosi cotoni egiziani Giza 45 e Giza 87. Le nostre camicie sono realizzate esclusivamente in Italia con tessuti prodotti in Italia.",
      p6: "TEXTA Srl nasce nel 2014 con l’obiettivo di realizzare tessuti stampati e uniti caratterizzati da elevata qualità e creatività. Oggi TEXTA è un punto di riferimento nella produzione di tessuti stampati e uniti per camiceria e abbigliamento maschile/femminile di pregio. Una moderna protagonista, dinamica ed effervescente che parla di creatività, esperienza, eccellenza e innovazione. In TEXTA la produzione è verticalizzata nelle sue fasi strategiche. Tutto nasce e cresce all’interno dell’azienda al fine di mantenere il completo controllo del processo produttivo grazie all’esperienza del personale -formato in azienda- utile a garantire l’autenticità del “Made in Italy”. TEXTA ha fatto della selezione delle migliori materie prime uno dei propri punti di forza nonché il primo passo verso la garanzia degli standard qualitativi più elevati in ogni passaggio del proprio processo produttivo.",
      ande:
        "Questo tessuto grigio Jersey è una scelta perfetta per realizzare una camicia casual elegante. Combina con i tuoi blue jeans e una giacca casual. Questo tessuto jersey è realizzato con filato melange 90/2.",
      squire:
        "Popeline rigoroso costruito con 62 fili 90/2 e 30 battute 60/1. Propone un vasto assortimento di righe e quadri sia classici che fantasia.",
      piave:
        "Tessuto doppio ritorto in 90/2, studiato in diverse armature con un'ampia offerta sia colori che di fantasie.",
      rock: "Stampa su base popeline ovo.",
      oxford70:
        "E' un tessuto creato con filati molto fini, che permettono di ottenere il tipico effetto puntinato dell’Oxford in maniera sofisticata e discreta. Resistente e brillante, Oxford 70 rivela unadensità meravigliosa e un touch morbido ed è perfetto sia per l’ufficio che per camicie casual.",
      twill70:
        "I tessuti Twill 70 sono l’incontro perfetto tra eleganza e versatilità. La struttura diagonale donaai tessuti twill un aspetto brillante, ma anche una mano morbida e una sensazione di comfort. La definizione dei disegni unita alla loro semplicità, rende Twill 70 il tessuto ideale per un perfetto gentleman urbano.",
      piquet70:
        "Rappresenta l’essenza dei tessuti armaturati di Albini. Grazie all’alta densità dell’ordito e della trama e all’abile combinazione di diverse strutture, è caratterizzato da nuovi effetti di tridimensionalità e sofisticati giochi di colore e luce.",
      saharaBright:
        "Albini Group seleziona esclusivamente il miglior lino europeo per creare tessuti freschi e naturali, dalle proprietà eccezionali: massima resistenza, elevata capacità di assorbimento dell’umidità, proprietà isolanti e termoregolatrici, caratteristiche anallergiche.",
      sJames:
        "E' un popeline inglese molto costruito in 100/2, eccezionale per il suo corpo e carattere.",
      duke:
        "Sono tante le differenze che rendono il tessuto Duke unico. Classico e versatile, viene realizzato con il miglior cotone a fibra extra-lunga, risultando altamente resistente all’usura e ai lavaggi, ma anche morbido, lucente e facile da curare. Un’ampia proposta di strutture crea nuovi giochi di luce e tridimensionalità.",
      piumino:
        "Una sensazione di leggerezza incantevole: Piumino viene realizzato con filati unici sottilissimi inpregiato cotone egiziano Giza 87. Un tessuto leggero ma al tempo stesso compatto, dal gusto profondamente italiano, caratterizzato da brillantezza, resistenza ed eleganza. Grazie al suo touch setoso,si adatta al corpo come una seconda pelle.",
      bruges:
        "Dall’unione del miglior cotone a fibra lunga e del lino europeo più prezioso nascono sofisticati tessuti misti lino che coniugano le migliori caratteristiche di queste due fibre naturali: la piacevolezza al tatto e resistenza tipica del cotone con la freschezza e creatività del lino.",
      superPiumino:
        "E' un tessuto setoso e compatto, realizzato con filati unici sottilissimi in pregiato cotone egiziano Giza 87. Grazie alla sua base ricca, Super Piumino viene presentato in diverse armature per adattarsi ogni giorno a uno stile elegante o informale.",
      silver:
        "Silver, il popeline tinto filo in 100/2, il cui nome è sinonimo di preziosità, brillantezza e purezza. Silver rapresenta la classica camicia inglese a righe e quadri, con la sua mano eccezionale, la vivacità del colore e la resistenza.",
      comfort:
        "E' un tessuto stretch compatto e luminoso, che garantisce una vestibilità ideale per uno stile di vita dinamico e contemporaneo e un’elasticità che rimarrà inalterata nel tempo. In trama, il filato di elastomero viene ricoperto dal cotone, offrendo una sensazione di naturalezza sulla pelle.",
      portland:
        "E' un raffinato e colorato popeline tessuto con un finissimo 120/2 in trama e ordito, caratterizzato da raffinata eleganza, definizione del colore e fascino senza tempo, insieme a una mano morbida e croccante che dura nel tempo.",
      victoriaFlam:
        "E' un tessuto denim caratterizzato da interessanti fiammature, che gli conferiscono da un aspetto vintage e naturale.",
      chambray:
        "Leggero e traspirante, Chambray è un tessuto con una mano morbida e fresca, ideale per la stagione estiva. Il sapiente utilizzo di filati fiammati ne esalta l’aspetto vivace e casual. Estremamente versatile, ma sempre raffinato, Chambray si adatta perfettamente a ogni occasione.",
      buckingham:
        "Un classico di eleganza e finezza, Buckingham è un popeline dal titolo 140/2, che prende il nome dalla maestosa residenza ufficiale di Sua Maestà Regina Elisabetta II.",
      zephir:
        "Lo stesso numero di fili per la trama e l’ordito rendono armonioso questo tessuto di cotone vichy finissimo, leggero e delicato come il vento di ponente.",
      royalOxford:
        "Spesso imitato, ma mai superato, il Royal Oxford è un incredibile tessuto dalla trama unica e inconfondibile. La combinazione di colore, brillantezza, filati doppio ritorti e la lunga tradizione dell'Oxford, contribuiscono a rendere questo tessuto unico nel suo genere ed estremamente versatile, perfetto per occasioni sia informali che formali.",
      journey:
        "La collezione Journey di Thomas Mason presenta innovativi tessuti ideali per chi desidera una camicia sempre in ordine, che non sgualcisce, anche dopo essere stata riposta in valigia. Grazie all’innovativo finissaggio, questi tessuti resistono alle pieghe e non richiedono cure particolari per mantenere il loro aspetto ordinato.",
      balmoral:
        "E' un tessuto prezioso che coniuga la morbidezza e il calore del cashmere, con la traspirabilità e il comfort del cotone. Il risultato è un tessuto leggero, dal touch piacevole e morbido per una camicia casual nobile ed elegante, bella da portare, semplice da curare e dal comfort davvero unico.",
    },
    nostriartigiani: {
      sotto1:
        "Le nostre camicie sono prodotte esclusivamente in Italia da abili artigiani, in laboratori sartoriali fra i più prestigiosi ed apprezzati del mondo.",
      p1:
        "I nostri esperti sarti, veri maestri la cui sapienza ed abilità si tramanda da generazioni, si occupano di tutto ciò che riguarda il confezionamento, assicurando così la massima precisione in ogni fase del processo di realizzazione, accoppiando i tessuti, realizzando cuciture del più alto livello destinate a durare nel tempo, utilizzando solo filati, accessori e componenti italiani di prima qualità.",
      p2:
        "Ogni fase della lavorazione, dal controllo del disegno al taglio delle stoffe, alla cucitura di ogni dettaglio è sottoposta a rigidi controlli di qualità, unendo all’artigianalità italiana le più moderne e precise tecnologie di produzione.",
      p3: "… i dettagli che fanno la differenza:",
      t4: "Asole e filo",
      p4:
        "Come ogni altro dettaglio delle nostre camicie, anche le asole e il filo che assicura il bottone, possono essere personalizzati liberamente, scegliendo tra una ampia gamma di colori.",
      t5: "Bottoni",
      p5:
        "I nostri bottoni sono applicati con metodo Ascolite®: un filo elastico saldato a caldo avvolge il piede del bottone conferendogli una grande resistenza.",
      t6: "Cucitura",
      p6:
        "La cucitura delle nostre camicie è realizzata a mano. Con chiusura 5mm a un ago, robusta e pregiata, ha una maggiore resistenza nel tempo rispetto a quella industriale.",
      t7: "Mosca",
      p7:
        "La mosca è un triangolino di tessuto cucito da ogni lato tra il davanti e il dietro della camicia, allo scopo di rinforzare la cucitura ed evitare strappi. E’ presente in tutte le nostre realizzazioni e può essere personalizzata a richiesta con cifre o loghi.",
      t8: "Spessore Colletto e Polsini",
      p8:
        "I nostri artigiani confezioneranno le camicie utilizzando colletti e polsini di spessore Medio. Spessori personalizzati di differente durezza (Ultra leggero, Leggero, Medio, Pesante, Ultra Pesante) sono sempre disponibili senza alcun aggravio di costo su richiesta del cliente. Forse non tutti lo sanno, o non vi hanno mai prestato troppa attenzione, ma le parti della camicia, cioè tutti quegli elementi di sartoria necessari alla sua realizzazione, hanno un nome ben preciso, rimasto inalterato nel corso del tempo.",
      p9:
        "Per quanto, con la produzione industriale e con l’evoluzione della moda, alcuni di questi elementi siano scomparsi, ancora oggi, secondo la tradizione della camiceria artigianale, una camicia di qualità non può dirsi tale se la sua manifattura non è il risultato dell’insieme di tutte queste parti. Per capire la complessità del lavoro della camiceria, proviamo a separare e a riconoscere, una per una, le parti più importanti della camicia, e a riconoscere nei dettagli ciò che ne determina la qualità:",
      t10: "Davanti",
      p10:
        "Si tratta esattamente della parte frontale di una camicia, o meglio delle due parti che la compongono, dove vengono applicati i bottoni e, talvolta, i taschini;",
      t11: "Taschino",
      p11:
        "Il taschino viene cucito di solito sul lato sinistro del davanti. E’ un dettaglio che compare generalmente nelle camicie sportive, specialmente se con collo button down, e nelle camicie da lavoro, dove ha una funzione di utilità, oltre che decorativa;",
      t12: "Cannoncino",
      p12:
        "Si tratta della fascia su cui sono ritagliate le asole. Il cannoncino cucito “a vista” è tipico delle camicie sportive, mentre nelle camicie eleganti e classiche non compare;",
      t13: "Polsino",
      p13:
        "La forma del polsino varia a differenza del tipo di camicia. Nei modelli classici, il polsino ha le punte mozze o arrotondate, spesso prevede l’inserimento dei gemelli, e la manica, nel punto di unione, presenta alcune piccole pieghe. Nei modelli sportivi, il polsino è dritto. L’apertura lungo la manica che segue al polsino è il fessino della manica, che di solito viene chiuso da un bottone;",
      t14: "Fianchi",
      p14:
        "Dalle cuciture lungo i fianchi si deduce facilmente la qualità della manifattura di una camicia. Infatti più è alta la densità dei punti, più la qualità è elevata: almeno 8/9 punti per centimetro significano una camicia eccellente;",
      t15: "Orlo",
      p15:
        "Si tratta della rifinitura eseguita sul fondo della camicia, variabile secondo il modello e il produttore;",
      t16: "Bottoni",
      p16:
        "Anche i bottoni rappresentano un segno di distinzione per una camicia di qualità. Nelle camicie eleganti, devono avere uno spessore di almeno 2/3 millimetri, meglio se in madreperla. Dietro al cannoncino dovrebbero essere cuciti almeno un paio di bottoni di scorta;",
      t17: "Giromanica",
      p17:
        "E’ il punto in cui viene inserita la manica, che nelle camicie di ottima qualità viene sempre ribattuto;",
      t18: "Carrè",
      p18:
        "E’ la parte della camicia che copre le spalle. Nelle camicie di qualità il carré è costituito da una parte separata dal resto della camicia, in tessuto doppio e tagliato in verticale;",
      t19: "Cannone e schiena",
      p19:
        "Si tratta del dietro della camicia, lavorato in maniera differente secondo il modello. Può essere liscio, con cannone centrale, con due pieghe laterali o con pinces nel caso di camicie slim, che seguono la forma del corpo;",
      t20: "Colletto",
      p20:
        "Anche il colletto si distingue secondo il modello e lo stile della camicia. I colletti più noti sono comunque italiano, francese e button down, fissato dai caratteristici bottoncini. Nelle camicie eleganti il colletto è mantenuto rigido dall’inserimento di stecchette in metallo, osso o materiale plastico.",
      t21: "Mosca",
      p21:
        "La mosca è un triangolino di tessuto cucito da ogni lato tra il davanti e il dietro della camicia, allo scopo di rinforzare la cucitura ed evitare strappi.",
    },
  },
  contact: {
    titolo: "Inviaci un messaggio",
    sottotitolo:
      "Contattaci anche solo per domande o suggerimenti. Ti risponderemo nel più breve tempo possibile.",
    nome: "Il tuo nome",
    telefono: "Il tuo telefono",
    email: "La tua email",
    messaggio: "Lascia un messaggio",
    invia: "Invia il messaggio",
  },
  header: {
    account: "Account",
    modificapassword: "Modifica Password",
    logout: "Logout",
    wishlist: "Wishlist",
    shopping: "Carrello",
    loggedOut: "Ora non sei più loggato.",
    loggedOutError: "Si è verificato un errore:",
    cambiaPasswordSuccess:
      "Ti è stata inviata una mail contenente un link unico per resettare la password.",
    cambiaPasswordError: "Si è verificato il seguente errore:",
  },
  coloriTip: {
    grigio: "Grigio",
    blu: "Blu",
    nero: "Nero",
    marrone: "Marrone",
    verde: "Verde",
    bianco: "Bianco",
    viola: "Viola",
    arancione: "Arancione",
    beige: "Beige",
    giallo: "Giallo",
    rosa: "Rosa",
    rosso: "Rosso",
  },
  footer: {
    account: "Account",
    login: "Login",
    logout: "Logout",
    cookie: "Gestione dei Cookie",
    privacy: "Privacy Policy",
    termini: "Termini e Condizioni",
    recesso: "Diritto di Recesso",
    contattaci: "Contattaci",
    partita: "P.IVA",
    about: "Chi siamo",
    ourfabrics: "I nostri tessuti",
    ourartisans: "I nostri artigiani",
    famiglie: "Descrizione famiglie tessuti",
  },
  dettaglio: {
    descrizione: "Descrizione tessuto",
    comeMisure: "Come prendere le misure",
    spedizione: "Spedizione e reso",
  },
  navigazione: {
    lang: "it",
    tessuto: "Tessuto",
    scegliTessuto: "Scegli il tessuto",
    inOfferta: "In offerta",
    nonDisponibile: "Non disponibile",
    personalizza: "Personalizza",
    personalizzazione: "Personalizzazione",
    polsinoGemelli: "Polsino Gemelli",
    polsinoDU: "Polsino doppio uso",
    infoDU: "Adatto per gemelli",
    contrastoColletto: "Applica al Colletto",
    contrastoPolsino: "Applica ai Polsini",
    contrastoChiusura: "Applica alla Chiusura",
    chiusura: "Chiusura",
    colletto: "Colletto",
    polsino: "Polsino",
    taschino: "Taschino",
    pinces: "Dietro",
    asole: "Asole",
    bottoni: "Bottoni",
    fili: "Fili",
    contrasto: "Contrasto",
    loading: "Caricamento...",
    mascherina: "Mascherina",
    maniche: "Maniche",
    info1: "Solo per Slim Fit",
    iniziali: {
      iniziali: "Iniziali",
      inserisciIniziali: "Inserisci le iniziali",
      tueIniziali: "Le tue iniziali...",
      stile: "Scegli lo stile",
      colore: "Scegli il colore",
      bianco: "Bianco",
      blu: "Blu",
      celeste: "Celeste",
      grigio: "Grigio",
      nero: "Nero",
      rosso: "Rosso",
      verde: "Verde",
      arancione: "Arancione",
      rosa: "Rosa",
      posizione: "Scegli la posizione",
      sopra: "Sopra",
      centro: "Centro",
      sotto: "Polsino",
      aggiungiIniziali: "Aggiungi iniziali",
      stile1: "stile",
      colore1: "colore",
      posizione1: "posizione",
    },
    misure: {
      uomo: "Uomo",
      donna: "Donna",
      bambino: "Bambino",
      selezionaTagliaBambino: "Taglia bambino",
      anni: "Anni",
      anno: "Anno",
      polo: "Stile Polo",
      vestito: "Vestito",
      metodoTitolo: "Seleziona il tuo metodo",
      metodoTaglia: "Misure da taglia standard",
      metodoCorpo: "Misura il tuo corpo",
      metodoCamicia: "Misura una camicia che ti va bene",
      metodoInvia: "Inviaci una camicia per misurarla",
      metodoModifica: "Modifica",
      metodoInformazioni: "Informazioni",
      selezionaTaglia: "Seleziona la tua taglia...",
      selezionaVestibilita: "Seleziona la tua vestibilità...",
      misure: "Misure",
      tueMisure: "Le tue misure",
      salva: "Salva le tue misure",
      errore: "Si è verificato un errore.",
      successo: "Misure salvate con successo.",
      Braccia: "Braccia",
      Collo: "Collo",
      Girovita: "Girovita",
      Polso: "Polso",
      Spalle: "Spalle",
      Torace: "Torace",
      Lunghezza: "Lunghezza",
      vuoiAiuto: "Vuoi un aiuto con le misure?",
      provaSelezionare: "Prova a selezionare la tua taglia",
      successoCarica: "Misure caricate con successo.",
      seleziona: "Seleziona...",
      xs: "XS (collo 38)",
      s: "S (collo 38)",
      m: "M (collo 39/40)",
      l: "L (collo 41/42)",
      xl: "XL (collo 43/44)",
      xxl: "XXL (collo 45/46)",
      xxxl: "XXXL (collo 47/48)",
      oppure: "oppure",
      stoCaricando: "Sto caricando...",
      carica: "Carica le tue misure",
      inviaTitolo: "Inviaci la camicia da misurare",
      inviaParag1:
        "Vuoi duplicare le misure di una camicia in tuo possesso, ma non sai come misurarla correttamente?",
      inviaParag2:
        "Inviaci la tua camicia ed i nostri esperti la misureranno e conserveranno le tue misure per i prossimi acquisti. *Nota che alla tua camicia non verrà arrecato alcun danno e ti verrà rispedita assieme al tuo acquisto.",
      inviaParag3: "Invia a:",
      inviaTitolo2: "Quando invii, sii sicuro di includere:",
      inviaLista1: "La camicia che vuoi farci misurare",
      inviaLista2: "Il tuo nome",
      inviaLista3: "Un recapito telefonico",
      inviaLista4: "Il tuo indirizzo email",
      inviaLista5: "L'indirizzo di spedizione",
      inviaLista6: "Eventuali istruzioni particolari",
      infoTipoMisure: "Tipo misure:",
      infoTipoMisure0: "misure da taglia",
      infoTipoMisure1: "misure da corpo",
      infoTipoMisure2: "misure da camicia",
      infoTipoMisure3: "invierà una camicia da misurare",
      infoTagliaSelezionata: "taglia selezionata:",
      infoVestibilita: "Vestibilità",

      tabellaTaglie: "Tabella Taglie",
      slimDonna: "CAMICIA SLIM DONNA",
      vestitoslimDonna: "VESTITO SLIM DONNA",
      regularDonna: "CAMICIA REGULAR DONNA",
      vestitoregularDonna: "VESTITO REGULAR DONNA",
      boyfriendDonna: "CAMICIA BOY-FRIEND DONNA",
      poloDonna: "CAMICIA POLO DONNA",
      camiciaUomo: "CAMICIA SLIM, REGULAR E STILE POLO UOMO",
      camiciaBambino: "CAMICIA SLIM, REGULAR E STILE POLO BAMBINO",
      small: "SMALL",
      medium: "MEDIUM",
      large: "LARGE",
      extralarge: "EXTRALARGE",
      Vita: "Vita",
      Manica: "Manica",
      statura: "Statura",
    },
  },

  barraAcquisto: {
    lang: "it",
    camicia: "Camicia",
    nonDisponibile: "Tessuto non disponibile.",
    tooltip: "Aggiungi la camicia al carrello",
    carrello: "Aggiungi al carrello",
    tessuto: "Tessuto ",
    di: " di ",
    aggiunta: "Camicia aggiunta al carrello.",
    aggiunta1: "La camicia è stata aggiunta al carrello con successo.",
    aggiunta2: "Cosa desideri fare ora?",
    continua: "Continua lo shopping",
    vai: "Carrello",
    wishlist: {
      lang: "it",
      nuovoPrezzo: "Nuovo prezzo",
      nonDisponibile: "Attenzione, tessuto non disponibile!",
      wishlist: "Wishlist",
      vuota: "La tua Wishlist è vuota!",
      Camicia: "Camicia",
      tooltip: "Aggiungi la camicia alla wishlist",
      successo: "Camicia aggiunta alla wishlist con successo.",
      rimossa: "Camicia rimossa dalla wishlist con successo.",
      Prodotto: "Prodotto",
      Prezzo: "Prezzo",
      Descrizione: "Descrizione",
      Tessuto: "Tessuto",
      Disegno: "Disegno",
      Colletto: "Colletto",
      Italiano: "Italiano",
      "Italiano Classico": "Italiano Classico",
      "Francese Classico": "Francese Classico",
      "Francese Semiaperto": "Francese Semiaperto",
      "Francese Semiaperto due bottoni": "Francese Semiaperto due bottoni",
      "Botton Down Classico": "Button Down Classico",
      "Botton Down Alto due bottoni": "Button Down Alto due bottoni",
      Diplomatico: "Diplomatico",
      Coreano: "Coreano",
      "Polsino Arrotondato": "Polsino Arrotondato",
      "Polsino Smussato": "Polsino Smussato",
      "Polsino Gemelli": "Polsino Gemelli",
      "Manica Corta": "Manica Corta",
      Chiusura: "Chiusura",
      "Faldoncino Interno non Impunturato": "Francese",
      "Faldoncino Esterno Impunturato": "Standard",
      "Finta Doppia e bottoni nascosti": "Nascosta",
      "Nessun Taschino": "Nessun Taschino",
      "Taschino Arrotondato": "Taschino Arrotondato",
      "Taschino Smussato": "Taschino Smussato",
      "Due Taschini Arrotondati": "Due Taschini Arrotondati",
      "Due Taschini Smussati": "Due Taschini Smussati",
      "Dietro Liscio": "Dietro Liscio",
      "Dietro con Doppie Pinces": "Dietro con Doppie Pinces",
      "Dietro con Doppia Piega": "Dietro con Doppia Piega",
      "Asola Bianca": "Asola Bianca",
      "Asola Blu": "Asola Blu",
      "Asola Celeste": "Asola Celeste",
      "Asola Grigia": "Asola Grigia",
      "Asola Nera": "Asola Nera",
      "Asola Rossa": "Asola Rossa",
      "Bottone Bianco": "Bottone Bianco",
      "Bottone Blu": "Bottone Blu",
      "Bottone Celeste": "Bottone Celeste",
      "Bottone Grigio": "Asola Grigia",
      "Bottone Nero": "Bottone Grigio",
      "Bottone Rosso": "Bottone Rosso",
      "Filo Bianco": "Filo Bianco",
      "Filo Blu": "Filo Blu",
      "Filo Celeste": "Filo Celeste",
      "Filo Grigio": "Filo Grigia",
      "Filo Nero": "Filo Grigio",
      "Filo Rosso": "Filo Rosso",
      Contrasto: "Contrasto",
      contrastoPolsino: "Polsino",
      contrastoColletto: "Colletto",
      contrastoChiusura: "Chiusura",
      Stile: "Stile",
      Iniziali: "Iniziali",
      iniStile: "Stile",
      iniColore: "Colore",
      iniPosizione: "Posizione",
      white: "Bianco",
      slateblue: "Blu",
      lightsteelblue: "Celeste",
      lightgray: "Grigio",
      black: "Nero",
      crimson: "Rosso",
      posizione: "Scegli la posizione",
      sopra: "Sopra",
      centro: "Centro",
      sotto: "Sotto",
      rimuovi: "Rimuovi dalla Wishlist",
      importa: "Importa nel configuratore",
      torna: "Torna al configuratore",
    },
  },
  tutorial: {
    braccia: {
      header: "Braccia",
      titolo: "Misura delle braccia",
      descrizione:
        "Misura la manica dalla spalla sino al polso tenendo il braccio piegato a 90°. Per una misurazione accurata, occorre misurare dalla spalla al gomito e proseguire fino alla la fine del polso, dove si unisce alla mano.",
    },
    bracciaCamicia: {
      header: "Braccia",
      titolo: "Misura delle braccia",
      descrizione:
        "Misura la manica dalla cucitura più alta della spalla sino alla fine del polsino, tenendo la camicia ben stesa su una superficie piana.",
    },
    collo: {
      header: "Collo",
      titolo: "Misura del collo",
      descrizione:
        "Misura subito al di sotto del pomo d'Adamo, facendo scorrere il metro intorno al collo per prendere la circonferenza. E' importante inserire un dito fra il metro ed il collo per garantire una aderenza comoda.",
    },
    colloCamicia: {
      header: "Collo",
      titolo: "Misura del collo",
      descrizione:
        "Misura la distanza lineare fra il bottone del colletto e l'asola corrispondente, tenendo il colletto ben aperto e steso su una superficie piana.",
    },
    girovita: {
      header: "Girovita",
      titolo: "Misura del girovita",
      descrizione:
        "Misura il giro vita nel punto più ampio all'altezza dell'ombelico. Se hai fianchi ampi è consigliabile prendere la misura leggermente più in basso, per comprenderli nel totale. Per una corretta vestibilità bisogna far aderire il metro alla vita lasciando sempre un dito di tolleranza tra il corpo ed il metro, per potersi sedere comodamente. E' importante prendere la misura precisa, tenendo il metro aderente al corpo: la vestibilità sarà data dal modello scelto.",
    },
    girovitaCamicia: {
      header: "Girovita",
      titolo: "Misura del girovita",
      descrizione:
        "Misura il giro vita nel punto più ampio all'altezza dell'ombelico, fra il secondo e il terzo bottone dal basso, tenendo la camicia ben stesa su una superficie piana.. Se hai fianchi ampi è consigliabile prendere la misura leggermente più in basso, per comprenderli nel totale.",
    },
    polso: {
      header: "Polso",
      titolo: "Misura del polso",
      descrizione:
        "Misura la circonferenza del polso all'altezza dell'articolazione. Per una corretta vestibilità bisogna far aderire il metro al polso conservando  un dito di tolleranza tra il polso ed il metro.",
    },
    polsoCamicia: {
      header: "Polso",
      titolo: "Misura del polso",
      descrizione:
        "Misura la distanza lineare fra il bottone del polsino e l'asola corrispondente, tenendo il polsino ben aperto e steso su una superficie piana.",
    },
    spalle: {
      header: "Spalle",
      titolo: "Misura delle spalle",
      descrizione: "Misurare la larghezza delle spalle nel punto più ampio.",
    },
    spalleCamicia: {
      header: "Spalle",
      titolo: "Misura delle spalle",
      descrizione:
        "Misurare la larghezza delle spalle nel punto più ampio, dalla cucitura superiore del braccio sinistro a quella del braccio destro, tenendo la camicia ben stesa su una superficie piana. ",
    },
    torace: {
      header: "Torace",
      titolo: "Misura del torace",
      descrizione:
        "Misura la circonferenza del torace all'altezza del petto. Per una corretta vestibilità bisogna far aderire il metro al torace conservando sempre un dito di tolleranza tra il corpo ed il metro in modo da potersi muovere in libertà.",
    },
    toraceCamicia: {
      header: "Torace",
      titolo: "Misura del torace",
      descrizione:
        "Misura la circonferenza del torace all'altezza del petto, fra il secondo ed il terzo bottone dall'alto. Misura la distanza lineare fra il bottone e l'opposta asola corrispondente.",
    },
    lunghezza: {
      header: "Lunghezza",
      titolo: "Misura della lunghezza",
      descrizione:
        "Inclina la testa in avanti e  misura dal centro della base posteriore del collo, in  corrispondenza dell’osso più sporgente, fino a 3/4 dei glutei per una lunghezza classica. Se indossi una camicia, misura il lato posteriore dal centro della cucitura del colletto, fino all'orlo inferiore.",
    },
    lunghezzaCamicia: {
      header: "Lunghezza",
      titolo: "Misura della lunghezza",
      descrizione:
        "Tenendo la camicia ben stesa su una superficie piana  misura dal centro della base posteriore del collo, in  corrispondenza del centro della cucitura fra camicia e colletto, fino all'orlo inferiore.",
    },
  },
  contatti: {
    richiesto: "Campo richiesto",
    emailInvalida: "Indirizzo email non valido",
    titolo: "Noi adoriamo dialogare con te",
    successo: "Grazie. Il tuo messaggio è stato inviato con successo.",
    errore: "Attenzione. Si è verificato un errore.",
    nome: "Nome *",
    email: "Email *",
    oggetto: "Oggetto *",
    messaggio: "Messaggio *",
    attendere: "Attendere...",
    invia: "Invia",
    testo01:
      "OGNI ORDINE HA BISOGNO DI ALMENO 20 GIORNI LAVORATIVI PRIMI DI ESSERE SPEDITO.CI SCUSIAMO PER IL DISAGIO MA LA VOSTRA CAMICIA NON SARÀ COME TUTTE LE ALTRE.",
    testo02:
      "La Sartoria Turrisi è a Ceglie Messapica, accogliente cittadina della Provincia di Brindisi, nel sud della Puglia.",
    testo03:
      "Dal 1970 tramandiamo la forte tradizione sartoriale, la nostra è una piccola azienda artigianale che opera con passione in ogni fase della lavorazione, eseguita esclusivamente all’interno della nostra sartoria da manodopera italiana altamente specializzata.",
    testo04:
      "La scelta dei tessuti e degli accessori di ottima qualità ci consentono di offrire l’eccellenza dell’autentico “Made in Italy”.",
    testo05:
      "Il nostro obiettivo è quello di soddisfare sotto ogni aspetto il nostro cliente, offrendo la nostra esperienza nel settore.",
    testo06:
      "Oggi è possibile confondere uno scultore con un sarto, perché tutti e due creano delle forme.",
    testo07: "KARL KRAUS",
    testo08: "TOCCA CON MANO LA NOSTRA SELEZIONE DI TESSUTI.",
    testo09: "ORDINA ORA IL TUO KIT GRATUITO!",
  },
  pagina404: {
    errore: "Errore 404",
    nonEsiste: "La pagina che cerchi non esiste!",
    torna: "Torna al configuratore",
  },
};
