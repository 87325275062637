export const lingua_ca = {
  lang: "ca",
  title: "Camisa 3D: camises personalitzades, configurador 3d",
  description: "Camises personalitzades, configurador 3d",
  personalizza: "Personalització",
  misure: "Midas",
  tessuto: "Teixit",
  filtri: "Filtres",
  scegliTessuto: "Tria el teixit",
  cercaTessuto: "Busca el teixit...",
  inOfferta: "En oferta",
  nonDisponibile: "No disponible",
  colore: "Color",
  colori: "Colors",
  tuttiColori: "Tots els colors",
  bianco: "Blanc",
  celeste: "Blau cel",
  altriColori: "Altres colors",
  disegno: "Dibuix",
  disegni: "Dibuixos",
  tuttiDisegni: "Tots els dibuixos",
  tintaUnita: "Llis",
  righe: "Ratlles",
  quadretti: "Quadres",
  fantasia: "Fantasia",
  materiale: "Teixit",
  materiali: "Teixits",
  tuttiMateriali: "Tots els teixits",
  cotone: "Cotó",
  lino: "Llí",
  brand: "Marca",
  brands: "Marcas",
  tuttiBrands: "Totes les marcas",
  codice: "Codi",
  ordina: "Ordena per preu",
  basso: "De baix cap a dalt",
  alto: "De dalt cap a baix",
  lato: "Vista lateral",
  front: "Vista frontal",
  retro: "Vista darrera",
  piegata: "Plegada",
  club: "Coll i Punys blancs",
  extra: {
    tutti: "Tots els productes",
    selTaglia: "Selecciona la mida",
    desc: "Descripció",
    correlati: "Productes relacionats",
  },
  banner: {
    titolo: "Us dels Cookies",
    testo1:
      "En aquesta aplicació, utilitzem cookies (o tecnologies similars) per emmagatzemar informació al vostre ordinador o dispositiu mòbil per tal de millorar la vostra experiència en línia. Les cookies són petits fitxers de text que permeten navegar de manera eficient, recordar les vostres preferències, ens ajuden a comprendre el rendiment de l’aplicació i en general milloren la vostra experiència d’usuari.",
    testo2:
      "En tancar aquest missatge, consentiu l’ús de cookies en funció de les vostres (possibles) preferències.",
    gestione: "Gestió dels Cookies",
    acconsento: "D'acord",
  },
  sito: {
    titolo: "Sartorie Italiane",
    sottotitolo:
      "De la unió entre tradició i innovació neix SartorieItaliane.it, un avançat portal de vendes online de camises fetes a mida. Amb un disseny gràfic clar i efectiu, és possible comprar productes de sastreria d’alta qualitat, fetes totalment a mida, i lliurats directament en el teu domicili.",
    bottone1: "Crea la teva camisa",
    titolo2: "Com funciona el configurador",
    sottotitolo2:
      "El configurador Sartorie Italiane permet una experiència de configuració fàcil, ràpida i atractiva.",
    titolo2a: "Rep les nostres novetats",
    sottotitolo2a:
      "Introdueix el teu correu electrònic per mantenir-te continuament informat sobre els nostres productes i ofertes ",
    configuratore: "Configurador",
    iscriviti: "Registra't",
    configura: "Crea",
    titolo3: "Tria el teixit",
    sottotitolo3:
      "Selecciona el més adient entre una àmplia gamma dels millors teixits Made in Italy.",
    titolo5: "Personalitza la teva camisa",
    sottotitolo5:
      "Tria els detalls per a crear la teva camisa ideal i verifica el seu efecte a temps real.",
    titolo7: "Introdueix les teves mides",
    sottotitolo7:
      "Segueix els tutorials del nostre configurador per a poder prendre les teves mides amb major precisió i professionalitat.",
    titolo6: "Unicitat del producte",
    sottotitolo6:
      "Al personalitzar el seu producte, l’usuari té la sensació de comprar algo especial i única, feta especialment per a ell.",

    titolo8: "Producció",
    sottotitolo8:
      "Les nostres camises vénen produïdes exclusivament a Itàlia per artesans especialitzats, en els tallers més prestigiosos del món. Els nostres sastres experts, veritables mestres i la qual saviesa i habilitat han sigut transmeses de generació en generació, s’ocupen de tot el relacionat amb la confecció, garantint així la màxima precisió en cada fase del procés de realització.",
    titolo9: " Web Marketing",
    sottotitolo9:
      "By offering an immersive browsing experience, the configurator helps to increase the brand's sales and awareness.",
    titolo10: "Main features of the 3D Shirt configurator",
    sottotitolo10:
      "The application guides the user from product customization to purchase.",
    titolo11: "Shirt customization",
    sottotitolo11:
      "Through a few simple steps, the shirt is configured in all its aspects, from the choice of fabric and other aspects of style, up to the insertion of the measures.",
    titolo12: "Purchase Process",
    sottotitolo12:
      "The application is able to manage all stages of the purchase process, from the management of the cart, products and discount codes, to the management of shipping and payment.",
    titolo13: "Account creation",
    sottotitolo13:
      "The application provides the ability to create a personal user profile that will allow the user to store his / her measurements and purchases.",
    chisiamo: {
      sotto1:
        "Ens esforcem per oferir als nostres clients la possibilitat de triar el seu propi estil amb absoluta llibertat, assegurant així que rebran sempre un producte d'altíssima qualitat.",
      p1: "Sartorie Italiane® és un marca italiana del grup Globalapulia Srl.",
      p2:
        "De la unió entre tradició i innovació neix sartorieitaliane.it, un avançat e-commerce de camises a mida. Amb un disseny gràfic clar i efectiu, és possible comprar productes de sastrería d'alta qualitat, fets totalment a mida i lliurats directament al teu domicili. Gràcies a un sistema de configuració ràpid i intuïtiu, l'usuari té la possibilitat de crear la seva pròpia camisa, personalitzant fins al mínim detall: des del coll, color de fil dels botons, punys fins a la possibilitat d'afegir i col·locar les inicials on desitgis. Gaudeix personalitzant la teva camisa a mida!",
      p3:
        "Gràcies al nostre sistema, podem conservar la teva silueta i mesures perquè, en un futur, puguis tornar a comprar la mateixa camisa. En el cas d'alguna variació, et recordem que pots indicar-nos tots els canvis necessaris per a satisfer les teves necessitats.",
      p4:
        "L'objectiu de Sartorie Italiane és obtenir una línia i un tall que s'adaptin a la teva figura i als teus gustos, posant a la teva disposició una àmplia gamma de detalls personalitzats i una gran experiència en els nostres artesans camisers.",
      p5:
        "Això és possible gràcies a la collaboració amb prestigiosos tallers artesans de sastres italians, on la passió per la moda i l'artesanal passen de generació en generació.",
    },
    nostritessuti: {
      sotto1:
        "Tria entre una àmplia gamma de teixits, tots ells produïts per les marques de més prestigi a la indústria tèxtil italiana.",
      p1:
        "Per a la teva camisa podràs triar entre els diferents teixits, acabats i combinacions de color. La nostra àmplia gamma de teixits i dissenys està en constant evolució gràcies als avenços i a les tendències de la indústria de la moda i del tèxtil.",
      p2:
        "La història de Canclini Tèxtils neix en 1925, quan va començar com a indústria de la seda a la zona de Como, Itàlia. Als anys 60 i amb el primer canvi generacional porta a la família, amb gran experiència en el processament d'un producte noble i de més alt nivell com la seda, a abandonar al sector de la seda i acostar-se al sector del cotó. Aquesta elecció positiva va portar a que en els anys posteriors, la venda de teixits s’hagués extés a mercats més enllà dels Alps, concretament a Alemanya i França. A finals dels 80, l'activitat de l'empresa va rebre un nou impuls a partir de la compra de maquinària moderna. Avui dia l'empresa es troba entre les marques líders en el camp dels teixits més fins per a les camises.",
      p3:
        "Albini S.p.A. És una empresa italiana amb seu a Albí (província de Bèrgam) que opera des de 1876 en el sector tèxtil, produint telixits per a camises de gamma alta. El 1992, vénen adquirides dues marques angleses, “Thomas Mason” i “David i John Anderson”, juntament amb el seu arxiu històric compost per més de 600 volums d’incalculable valor. El procés d’expansió va continuar amb nous centres de producció i l’entrada de la marca “Albiate 1830”, comprada el 2000 per la família Caprotti.",
      p4:
        "Creativitat, innovació i experimentació: aquestes són les coordenades que caracteritzen el viatge de recerca del qual Albiate 1830 és el protagonista absolut. Gràcies a la seva identitat dinàmica i pionera, pot revelar nous itineraris i destinacions diferents per a la roba casual contemporània. En un viatge d'exploració que reforça el vincle amb les tendències de moda emergents, sense perdre de vista l'excepcional tradició de fabricació que brinda fa ja gairebé dos segles des del cor de la marca. Una essència que impulsa als Exploradors Tèxtils Italians de Albiate 1830 a crear teixits d'estil i qualitat sense igual. Amb les seves bases esportives, estampats excèntrics, jacquards originals i gran varietat de tonalitats, la marca està dirigida a un públic jove amb una ànima rebel, que es confirma com el líder indiscutible en roba de carrer de luxe. Albiate 1830 és molt més que una marca: és l'expressió d'una recerca creativa única, en la qual les matèries primeres exclusives, els detalls icònics i l'experimentació donen vida a un inesperat i sorprenent viatge d'estil.",
      p5:
        "Fundada en Lancashire en 1796 per l’empresari Thomas Mason, la seva marca homònima va ser un punt de referencia en el món de la camiseria anglès per l’elegància i la qualitat dels seus productes. Adquirida per Albini Grup el 1992, Thomas Mason reinterpreta el seu ric patrimoni amb un estil modern i original, a través de teixits dobles creats amb el fi lli de Normandia i els preuats cotons egipcis Giza 45 y Giza 87.",

      ande:
        "Aquesta tela gris de Jersey és una elecció perfecta per fer una camisa elegant i informal. Combina amb els teus texans i una jaqueta casual. Aquesta tela de Jersey està feta de fil melange 90/2.",
      squire:
        "Popelín rigorosa construïda amb 62 fils 90/2 i 30 beats 60/1. Ofereix una àmplia varietat de ratlles i quadrats clàssics i estampats.",
      piave:
        "Teixit de doble torsió en 90/2, estudiat en diferents teixits amb una àmplia gamma de colors i estampats.",
      rock: "Estampat sobre base de popelín ovo.",
      oxford70:
        "És un teixit creat amb filats molt fins, que permeten obtenir el típic efecte puntejat de l'Oxford de forma sofisticada i discreta. Resistent i brillant, Oxford 70, amb la seva meravellosa densitat i tacte suau, és perfecte tant per a la vida d'oficina com per als contextos més informals.",
      twill70:
        "Els teixits Twill 70 són la unió perfecta entre elegància i versatilitat. L'estructura diagonal proporciona als teixits twill un aspecte brillant, però també un tacte suau i una sensació de confort. La definició dels dissenys unida a la seva senzillesa fa de Twill 70 el teixit ideal per a un perfecte cavaller de ciutat.",
      piquet70:
        "Representa l'essència dels teixits trenats d'Albini. Gràcies a l'alta densitat de l'ordit i de la trama, i a l'hàbil combinació de diferents estructures, es caracteritza per nous efectes de tridimensionalitat i sofisticats jocs de llum i color.",
      saharaBright:
        "Albini Group només selecciona el millor lli europeu per crear teixits frescos i naturals, amb propietats excepcionals: màxima resistència, alta capacitat d'absorció de la humitat, propietats aïllants i termoreguladores i característiques hipoalergèniques.",
      sJames:
        "És una antiga popelín anglesa a títol 100/2 estructurada de manera esplèndida i excepcional pel seu cos i caràcter.",
      duke:
        "Hi ha moltes diferències que fan únic a el teixit Duke. Clàssic i versàtil, es realitza amb el millor cotó de fibra extrallarga, de manera que resulta altament resistent a el desgast i als rentats, però també és suau, lluent i fàcil de tractar. Una àmplia proposta d'estructures crea nous jocs de llum i tridimensionalitat.",
      piumino:
        "Una sensació d'agradable lleugeresa: Piumino està realitzat amb finíssims filats únics de preuat cotó egipci Gizeh 87. Un teixit lleuger alhora que compacte, amb un caràcter profundament italià, caracteritzat per la seva brillantor, resistència i elegància. Gràcies al seu tacte sedós, s'adapta a el cos com una segona pell.",
      bruges:
        "A partir de la unió del millor cotó de fibra llarga i de el lli europeu més preuat, neixen sofisticats teixits de lli mixt que combinen les millors característiques d'aquestes dues fibres naturals: el tacte agradable i la resistència típica de l'cotó amb la frescor i creativitat de l' lli.",
      superPiumino:
        "És un teixit sedós i compacte, realitzat amb finíssims filats únics de preuat cotó egipci Gizeh 87. Gràcies a la seva rica base, Super Piumino es presenta en diferents trenats per adaptar-se cada dia a un estil elegant o informal.",
      silver:
        "Silver, la pepelina de color teixida de títol 100/2, el nom és sinònim de perfecció, blancor lluent i puresa. Silver representa la clàssica camisa anglesa a ratlles i a quadres, amb un tacte sense igual, un color vibrant i la seva gran durabilitat.",
      comfort:
        "És un teixit elàstic, compacte i lluminós que garanteix una vestibilidad ideal per a un estil de vida dinàmic i contemporani i una elasticitat permanent fins i tot amb el pas el temps. En trama, el filat de elastòmer es recobreix de cotó, oferint una sensació natural sobre la pell.",
      portland:
        "És una refinada popelín de color teixida en ordit i trama supèrflues 120/2, caracteritzada per la seva fina elegància, definició de colors i atractiu atemporal, juntament amb un excepcional tacte suau i alhora aspre sorprenentment durador.",
      victoriaFlam:
        "És un teixit denim caracteritzat per interessants tocs flamejats que li confereixen un aspecte vintage i natural.",
      chambray:
        "Lleuger i transpirable, el Chambray és un teixit amb una tacte suau i fresc, ideal per a la temporada d'estiu. L'hàbil ús de fils amb efecte flamejat realça el seu aspecte alegre i desenfadat. Extremadament versàtil, però sempre refinat, el Chambray s'adapta perfectament a qualsevol ocasió.",
      buckingham:
        "Un clàssic d'elegància i finor, Buckingham és una popelín de títol 140/2 i porta el nom de la gran residència oficial del seu majestat la Reina Isabel II.",
      zephir:
        "Debido al mismo número de hilos en la trama y que en la urdimbre, este tejido de algodón vichy finísimo, ligero y delicado como el viento de ponente, adquiere una gran armonía.",
      royalOxford:
        "Sovint imitat, però mai superat, el Royal Oxford és un bonic teixit amb una distintiva i significativa textura. La combinació de color, brillantor, fils dobles juntament amb la llarga tradició de l'Oxford contribueixen a l'esperit d'aquest excepcional teixit, que és extremadament versàtil i s'adapta tant a ocasions formals com informals.",
      journey:
        "La col·lecció Journey de Thomas Mason presenta teixits innovadors ideals per a aquells que busquen una camisa sempre perfecta i sense arrugues, fins i tot després de sortir de la maleta. Gràcies al seu innovador acabat, aquests teixits són resistents a les arrugues i no requereixen cap cura especial per mantenir el seu aspecte en ordre.",
      balmoral:
        "És un teixit preciós que combina la suavitat i la calor de l'caixmir amb la transpirabilitat i el confort de l'cotó. El resultat és un teixit lleuger, amb un tacte agradable i suau ideal per a una camisa informal distingida i elegant, bonica estèticament, de fàcil cura i amb un confort únic.",
    },
    nostriartigiani: {
      sotto1:
        "Les camises de Sartorie Italiane es produeixen exclusivament a Itàlia, gràcies als sastres amb els quals treballem i en els tallers artesanals més prestigiosos i preuats del món.",
      p1:
        "Els nostres sastres són veritables mestres la saviesa i l'habilitat dels quals passen de generació en generació. Ells s'ocupen de tot el procés de la confecció, d'aquesta manera està garantida la màxima precisió en cada fase del projecte de fabricació. Mitjançant la unió dels teixits, es creen costures de gran nivell que fan que siguin més duradores en el temps, utilitzant únicament fil, accessoris i components italians de primera qualitat.",
      p2:
        "Cada fase del procés se sotmet a estrictes controls de qualitat que van des del disseny, tall dels teixits fins a passar per les costures.",
      p3:
        "En definitiva, una combinació d'artesania italiana que fan que els detalls marquin la diferència.",
      t4: "Traus i fils",
      p4:
        "Igual que tots els detalls de les nostres camises, els traus i els fils que asseguren el botó es poden personalitzar al teu gust, ja que poden triar-se d’entre una àmplia gamma de colors.",
      t5: "Botons",
      p5:
        "Els nostres botons vénen aplicats amb el mètode Ascolite®, és a dir posteriorment al fet que s'hagin cosit aquests se sotmeten a un procés patentat que fan que el botó tingui una gran flexibilitat i resistència en el temps.",
      t6: "Costura",
      p6:
        "La costura de les nostres camises es realizen a mà. Amb tancament de 5mm a una agulla, robusta y de valor, tene una resistencia superior a la industrial.",
      t7: "Mosca",
      p7:
        "La mosca és un petit triangle de teixit cosit a cada costat per davant i per darrere de la camisa. Té l'objectiu de donar força a la costura i evitar que es trenqui. Es troba present a totes les nostres peces i es pot personalitzar segons es desitgi, ja sigui xifres o logotips.",
      t8: "Espessor coll i punys",
      p8:
        "Els nostres artesans realitzaran les camises amb colls i punys de gruix mitjà. Gruixos personalitzats de diferent maduresa (Ultra Lleuger, Lleuger, Mitjà, Pesat, Molt pesat) estan sempre disponibles sense cap increment del preu final a petició del client. Potser no tothom ho sap o mai han prestat massa atenció, però les parts de la camisa, és a dir, tots els elements de sastreria necessaris per a la seva realització, tenen un nom precís que roman invariable en el temps.",
      p9:
        "Segons els artesans camisers, i malgrat que amb la producció industrial i l'evolució de la moda alguns d'aquests elements han desaparegut, una camisa de qualitat no pot dir-se “camisa” sense el conjunt de totes les parts que el formen. Per a entendre la complexitat del treball de l'artesà tractarem de separar i reconèixer, una per una, totes les parts més importants de la camisa, així com entrant detalladament en tot el que determina la qualitat.",
      t10: "Davant",
      p10:
        "Es tracta exactament de la part frontal d’una camisa, o millor de les dues parts que la composen, on vénen aplicats els botons i de vegades les butxaques.",
      t11: "Butxaca",
      p11:
        "La butxaca es cus generalment a la banda esquerra de la part frontal. És un detall que sol aparèixer en les camises més d’esport, especialment si ve realitzada amb un coll button down, i en les camises de treball, on té una funció d’utilitat i decorativa;",
      t12: "Canó",
      p12:
        "Es tracta de la banda en la qual vénen tallades les ranures. El canó cosit “a vista” és típic de les camises d’esport, mentre que en les camises elegants i clàssiques no apareixen;",
      t13: "Puny",
      p13:
        "La forma del puny varia a diferència del tipus de camisa. Als models clàssics, el puny té les puntes arrodonides, sovint considera el fet de fer servir els bessons, i la màniga, en el punt d’unió, presenta petits plecs. Mentres que als models esportius, el puny és recte. L’obertura al llarg de la màniga que segueix el puny és la tapeta de la màniga, que normalment es tanca amb un botó.",
      t14: "Costat",
      p14:
        "Des de les costures al llarg del costat es pot deduir fàcilment la qualitat de fabricació d’una camisa. De fet, com més gran sigui la densitat dels punts, de major qualitat serà la camisa: almenys 8/9 punts per centímetre signifiquen una excellent camisa.",
      t15: "Bora",
      p15:
        "És l’acabat realitzat a la part inferior de la camisa, variable segons el model i el fabricant.",
      t16: "Botons",
      p16:
        "Els botons també representen una marca de distinció per a una camisa de qualitat. A les camises elegants, han de tenir un gruix d’almenys 2/3 mm, preferentment de nacre. Darrere del canó haurien de ser cosits com a mínim un parell de botons de recanvi.",
      t17: "Cises",
      p17:
        "És el punt en el qual s’insereix la màniga, que en les camises de gran qualitat ve sempre cosit dues vegades.",
      t18: "Carrè",
      p18:
        "És la part de la camisa que cobreix les espatlles. En les camises de qualitat està composada per una peça separada de la resta de la camisa, de doble tela i tallada verticalment.",
      t19: "Canó i esquena",
      p19:
        "Aquesta és la part de darrere de la camisa, confeccionada de forma diferent segons el model. Pot ser llis, amb un canó central, amb dos plecs laterals o amb pinces en el cas de les camises entallades que segueixen la forma del cos.",
      t20: "Coll",
      p20:
        "El coll també es distingeix segons el patró i l'estil de la camisa. Els colls més coneguts són: italià, francès, button down i el fixat pels característics botons. A les camises elegants el coll es manté rígid per la inserció de llistons en metall, os o material plàstic. * Recomanem extreure els llistons durant la fase de rentada i planxat de la camisa.",
      t21: "Mosca",
      p21:
        "La mosca és un petit triangle de tela cosit en els laterals de la camisa, situat exactament en el punt mitjà entre el front i la part posterior d'aquesta. Serveix per a reforçar la costura i evitar que la camisa es trenqui.",
    },
  },
  contact: {
    titolo: "Envia'ns un missatge",
    sottotitolo:
      "Contacte amb nosaltres per sol·licitar informació o simplement per a preguntes o suggeriments relacionats amb la teva comanda. Farem tot el possible per respondre’t el més aviat.",
    nome: "El teu nom",
    telefono: "El teu telèfon",
    email: "Escriu el teu e-mail",
    messaggio: "Escriu el teu missatge",
    invia: "Envia el teu missatge",
  },
  header: {
    account: "Compte",
    modificapassword: "Cambia tu contrassenya",
    logout: "Sortir",
    wishlist: "Wishlist",
    shopping: "Carrito",
    loggedOut: "Encara no t'has registrat",
    loggedOutError: "Hi ha hagut un error:",
    cambiaPasswordSuccess:
      "T’enviarem un correu electrònic amb un enllaç únic per restablir la contrasenya.",
    cambiaPasswordError: "Hi ha hagut el seguent error",
  },
  coloriTip: {
    grigio: "Gris",
    blu: "Blau",
    nero: "Negre",
    marrone: "Marró",
    verde: "Verd",
    bianco: "Blanc",
    viola: "Violeta",
    arancione: "Taronje",
    beige: "Beige",
    giallo: "Groc",
    rosa: "Rosa",
    rosso: "Vermell",
  },
  footer: {
    account: "Usuari",
    login: "Entrar",
    logout: "Sortir",
    cookie: "Politica de Cookie",
    privacy: "Politica de Privacitat",
    termini: "Termes i condicions",
    recesso: "Dret de desistiment",
    contattaci: "Contacte",
    partita: "CIF",
    about: "Qui som",
    ourfabrics: "Els nostres teixits",
    ourartisans: "Els nostres artesans",
    famiglie: "Descripció familia teixits",
  },
  dettaglio: {
    descrizione: "Descripció del teixit",
    comeMisure: "Com prendre les teves mides",
    spedizione: "Enviament i devolució",
  },
  navigazione: {
    lang: "cat",
    tessuto: "Teixit",
    scegliTessuto: "Selecciona el teixit",
    inOfferta: "En oferta",
    nonDisponibile: "No disponible",
    personalizza: "Personalitza",
    personalizzazione: "Personalització",
    polsinoGemelli: "Punyo bessons",
    polsinoDU: "Bessons doble us",
    infoDU: "Adapte també per a bessons",
    contrastoColletto: "Aplica al coll",
    contrastoPolsino: "Aplica al puny",
    contrastoChiusura: "Aplica al tancament",
    chiusura: "Tancament",
    colletto: "Coll",
    polsino: "Puny",
    taschino: "Butxaca",
    pinces: "Darrera",
    asole: "Traus",
    bottoni: "Botons",
    fili: "Fil",
    contrasto: "Contrast",
    mascherina: "Màscara",
    maniche: "Sleeves",
    info1: "Slim Fit only",
    desMascherina: "Completa el tea look amb la màscara del teixit seleccionat",
    iniziali: {
      iniziali: "Inicials",
      inserisciIniziali: "Escriu les teves inicials",
      tueIniziali: "Escriu les teves inicials",
      stile: "Estil inicials",
      colore: "Color inicials",
      bianco: "Blanc",
      blu: "Blau",
      celeste: "Blau cel",
      grigio: "Gris",
      nero: "Negre",
      rosso: "Vermell",
      posizione: "Posició",
      sopra: "A dalt",
      centro: "Al centre",
      sotto: "Puny",
      polsino: "Puny",
      aggiungiIniziali: "Afegeix inicials",
      stile1: "Estil",
      colore1: "Color",
      posizione1: "Posició",
    },
    misure: {
      uomo: "Home",
      donna: "Dona",
      bambino: "Nen",
      metodoTitolo: "Selecciona el metode que prefereix",
      metodoTaglia: "Mesures de talles estàndard",
      metodoCorpo: "Medeix el teu cos",
      metodoCamicia: "Medeix una camisa que et vagi bè",
      metodoInvia: "Envia’ns la teva camisa per mesurar-la",
      metodoModifica: "Modificacions",
      metodoInformazioni: "Informaciones",
      selezionaTaglia: "Talla adult",
      selezionaTagliaBambino: "Talla nen",
      anni: "Anys",
      anno: "Any",
      polo: "Estil Polo",
      vestito: "Vestit",
      misure: "Mides",
      tueMisure: "Les teves mides",
      salva: "Salva",
      misureStandard:
        "Selecciona la talla desitjada. Nosaltres pensarem en la resta",
      errore: "Hi ha hagut un error",
      successo: "Mides salvadas amb exit",
      Braccia: "Braços",
      Collo: "Coll",
      Girovita: "Cintura",
      Polso: "Puny",
      Spalle: "Espatlles",
      Torace: "Torax",
      Lunghezza: "Llargada",
      vuoiAiuto: "Vols una ajuda amb les teves mides?",
      provaSelezionare: "Prova de seleccionar la teva mesura",
      successoCarica: "Mides carregadas amb exit.",
      seleziona: "Selecciona...",
      xs: "XS (Coll 38)",
      s: "S (Coll 38)",
      m: "M (Coll 39/40)",
      l: "L (Coll 41/42)",
      xl: "XL (Coll 43/44)",
      xxl: "XXL (Coll 45/46)",
      xxxl: "XXXL (Coll 47/48)",
      oppure: "o",
      stoCaricando: "Carregant...",
      carica: "Carga les teves midas",
      inviaTitolo: "Envia'ns una camisa per mesurar-la",
      inviaParag1:
        "Vols copiar les mesures d’una camisa que ja tens, però no estas segur de com mesurar-la correctament?",
      inviaParag2:
        "Envia-ns la teva camisa i els nostres experts la mesuraran i et salvaran les teves mides directament al teu compte.",
      inviaParag2a:
        "*Tingues en compte que no causarem cap dany a la camisa i te la enviarem amb la teva compra.",
      inviaParag3: "Envia a:",
      inviaTitolo2: "Quan enviis, assegura't de posar:",
      inviaLista1: "La camisa que vols mesurar",
      inviaLista2: "Tu nom",
      inviaLista3: "Tu telefon",
      inviaLista4: "Tu correu electrònic",
      inviaLista5: "Adreça d'enviament de devolució",
      inviaLista6: "Qualsevol instrucció especial",
      infoTipoMisure: "Metode de mesura:",
      infoTipoMisure0: "Mesures de talles estàndard",
      infoTipoMisure1: "Mesures preses al teu cos",
      infoTipoMisure2: "Mesures preses de la camisa",
      infoTipoMisure3: "Enviarè una camisa per mesurar-la",
      infoTagliaSelezionata: "Talla seleccionada:",
      infoVestibilita: "Vestibilitat",

      tabellaTaglie: "Guia de talles",
      slimDonna: "CAMISA SLIM DONA",
      vestitoslimDonna: "VESTIT SLIM DONA",
      regularDonna: "CAMISA REGULAR DONA",
      vestitoregularDonna: "VESTIT REGULAR DONA",
      boyfriendDonna: "CAMISA BOY-FRIEND DONA",
      poloDonna: "CAMISA POLO DONA",
      camiciaUomo: "CAMISA SLIM, REGULAR I ESTIL POLO HOME",
      camiciaBambino: "CAMISA SLIM, REGULAR I ESTIL POLO NEN",
      small: "SMALL",
      medium: "MEDIUM",
      large: "LARGE",
      extralarge: "EXTRALARGE",
      Vita: "CINTURA",
      Manica: "BRAÇOS",
      statura: "ALÇADA",
    },
  },
  barraAcquisto: {
    lang: "cat",
    camicia: "Camisa",
    nonDisponibile: "Teixit no disponible.",
    tooltip: "Afegeix aquesta camisa al carro",
    carrello: "Afegeix al carro",
    tessuto: "Teixit ",
    di: " de ",
    aggiunta: "Camisa afeigida al carro.",
    aggiunta1: "Camisa afeigida amb èxit al carro.",
    aggiunta2: "Què vols fer ara??",
    continua: "Segueix comprand",
    vai: "Anar al carro",
    wishlist: {
      lang: "cat",
      nuovoPrezzo: "Nou preu",
      nonDisponibile: "Atenció, teixit no disponible",
      wishlist: "Wishlist",
      vuota: "La teva Wishlist està buida!",
      Camicia: "Camisa",
      tooltip: "Afegeix la teva camisa a la Wishlist",
      successo: "Camisa afeigida amb èxit a la Wishlist.",
      rimossa: "Camisa eliminada amb èxit de la Wishlist.",
      Prodotto: "Producte",
      Prezzo: "Preu",
      Descrizione: "Descripció",
      Tessuto: "Teixit",
      Disegno: "Dibuix",
      Colletto: "Coll",
      Italiano: "Italiano",
      "Italiano Classico": "Italiano Classic",
      "Francese Classico": "Frances Classic",
      "Francese Semiaperto": "Semi Frances",
      "Francese Semiaperto due bottoni": "Semi Frances dos botons",
      "Botton Down Classico": "Button Down",
      "Botton Down Alto due bottoni": "Button Down dos botons",
      Diplomatico: "Diplomatico",
      Coreano: "Coreano",
      "Polsino Arrotondato": "Puny rodó",
      "Polsino Smussato": "Puny bisellat",
      "Polsino Gemelli": "Puny bessons",
      "Manica Corta": "Màniga curta",
      Chiusura: "Tancament",
      "Faldoncino Interno non Impunturato": "Frances",
      "Faldoncino Esterno Impunturato": "Standard",
      "Finta Doppia e bottoni nascosti": "Amagat",
      "Nessun Taschino": "Sense butxaca",
      "Taschino Arrotondato": "Butxaca rodó",
      "Taschino Smussato": "Butxaca bisellada",
      "Due Taschini Arrotondati": "Dos butxacas rodones",
      "Due Taschini Smussati": "Dos butxacas bisellades",
      "Dietro Liscio": "Darrere llis",
      "Dietro con Doppie Pinces": "Pince Central",
      "Dietro con Doppia Piega": "Pinces Laterales",
      "Asola Bianca": "Trau blanc",
      "Asola Blu": "Trau blau",
      "Asola Celeste": "Trau blau cel",
      "Asola Grigia": "Trau gris",
      "Asola Nera": "Trau negre",
      "Asola Rossa": "Trau vermell",
      "Bottone Bianco": "Botó blanc",
      "Bottone Blu": "Botó blau",
      "Bottone Celeste": "Botó blau cel",
      "Bottone Grigio": "Botó gris",
      "Bottone Nero": "Botó negre",
      "Bottone Rosso": "Botó vermell",
      "Filo Bianco": "Fil blanc",
      "Filo Blu": "Fil Blau",
      "Filo Celeste": "Fil blau cel",
      "Filo Grigio": "Fil gris",
      "Filo Nero": "Fil negre",
      "Filo Rosso": "Fil vermell",
      Contrasto: "Contraste",
      contrastoPolsino: "Puny",
      contrastoColletto: "Coll",
      contrastoChiusura: "Tancament",
      Stile: "Estil",
      Iniziali: "Inicials",
      iniStile: "Estil",
      iniColore: "Color",
      iniPosizione: "Posició",
      white: "Blanc",
      slateblue: "Blau",
      lightsteelblue: "Blau cel",
      lightgray: "Gris",
      black: "Negre",
      crimson: "Vermell",
      posizione: "Posició",
      sopra: "A dalt",
      centro: "Centre",
      sotto: "a baix",
      rimuovi: "Treure de la Wishlist",
      importa: "Importar al configurador",
      torna: "Tornar al configurador",
    },
  },
  tutorial: {
    braccia: {
      header: "Braços",
      titolo: "Llargada del braç",
      descrizione:
        "Mesura la màniga des de l’espatlla fins al canell sostenint el braç inclinat a 90 °. Per a una mesura precisa, cal mesurar des de l’espatlla fins al colze i procedir fins a l’extrem del canell, on s’uneix a la mà.",
    },
    bracciaCamicia: {
      header: "Braços",
      titolo: "Llargada del braç",
      descrizione:
        "Mesura la màniga des de la costura de l’espatlla fins al final del puny. Per a una mesura precisa, cal mantenir la màniga plana en una superfície plana.",
    },
    collo: {
      header: "Coll",
      titolo: "Mesura del coll",
      descrizione:
        "Mesura immediatament sota la poma d'Adam, llisant la cinta mètrica al voltant del coll per agafar la circumferència. És important inserir un dit entre la cinta de mesura i el coll per assegurar-se una vestibilitat còmoda.",
    },
    colloCamicia: {
      header: "Coll",
      titolo: "Mesura del coll",
      descrizione:
        "Mesura la distància lineal entre el botó del coll i el trau corresponent, mantenint el coll ben obert i estirat sobre una superfície plana.",
    },
    girovita: {
      header: "Cintura",
      titolo: "Mesura de la cintura",
      descrizione:
        "Mesura la línia de la cintura al punt més ample, a l’alçada del melic. Si tens caderas amples, és recomanable que la mesura s'ha da prendre desde mes a baix, per cosiderar la mesura total. Per a una mesura adequada, la cinta has te que adherir a la cintura, deixant sempre un dit de tolerància entre el cos i la cinta de mesura, de manera que us pugueu asseure còmodament. És important prendre la mesura precisa, mantenint la cinta mètrica adherida al cos. La vestibilitat es donarà per l’estil triat durant la personalització al configurador de la camisa.",
    },
    girovitaCamicia: {
      header: "Cintura",
      titolo: "Mesura de la cintura",
      descrizione:
        "Mesura la línia de la cintura al punt més ample del melic, entre el segon i el tercer botó de la part inferior, mantenint la camisa plana sobre una superfície plana. Si tens caderas amples, és recomanable que la mesura sigui lleugerament inferior, per incloure-les en la mesura total.",
    },
    polso: {
      header: "Puny",
      titolo: "Mesura del puny",
      descrizione:
        "Mesura la circumferència del canell a l'articulació del canell. Per a una mesura adequada, has de fer que la cinta mètrica s’adhereixi al canell mantenint un dit de tolerància entre el canell i la cinta de mesurament.",
    },
    polsoCamicia: {
      header: "Puny",
      titolo: "Mesura del Puny",
      descrizione:
        "Mesura la distància lineal entre el botó del puny i el trau corresponent, mantenint el puny obert i estès pla sobre una superfície plana.",
    },
    spalle: {
      header: "Espatlla",
      titolo: "Mesura de la espatlla",
      descrizione: "Mesura l'amplada de les espatlles al punt més ampli.",
    },
    spalleCamicia: {
      header: "Espatlla",
      titolo: "Mesura de la espatlla",
      descrizione:
        "Mesura l'amplada de l'espatlla en el punt més ampli, des de la costura del braç esquerra fins a la costura del braç dret, mantenint la camisa plana sobre una superfície plana.",
    },
    torace: {
      header: "Torax",
      titolo: "Mesura del torax",
      descrizione:
        "Mesura la circumferència del torax a l'alçada del mugró. Per a una mesura adequada, has d’adherir la cinta mètrica al torax, sempre mantenint un dit de tolerància entre el cos i la cinta de mesura de manera que et puguis moure lliurement.",
    },
    toraceCamicia: {
      header: "Torax",
      titolo: "Mesura del torax",
      descrizione:
        "Mesura la circumferencia del torax posant la cinta metrica d'una punta a l'altra, sota l'inici de la costura de la sisa",
    },
    lunghezza: {
      header: "Llargada",
      titolo: "Mesura de la llargada",
      descrizione:
        "Inclina el cap cap endavant i mesura des del centre de la base posterior del coll, a l’os més sobresortint, fins a 3/4 de les natges per una llargada clàssica.",
    },
    lunghezzaCamicia: {
      header: "Llargada",
      titolo: "Mesura de llargada",
      descrizione:
        "Mantenint la camisa plana sobre una superfície plana, mesura desde la base posterior del coll al centre de la costura entre la camisa i el coll, fins a la part final de la camisa en linea recta.",
    },
  },
  contatti: {
    richiesto: "Camp obligatori",
    emailInvalida: "Adressa de correu no vàlida",
    titolo: "Ens encanta parlar amb tu",
    successo: "Gràcies. El teu missatge s'ha enviat correctament.",
    errore: "Atenció. S'ha produït un error.",
    nome: "Nombre *",
    email: "Correu electrònic *",
    oggetto: "Objecte *",
    messaggio: "Missatge *",
    attendere: "Espera...",
    invia: "Envia",
    testo01:
      "EVERY ORDER REQUIRES AT LEAST 20 WORKING DAYS BEFORE SHIPPING. WE APOLOGIZE FOR THIS ANNOYANCE BUT YOUR SHIRT WILL NOT BE LIKE ALL THE OTHERS.",
    testo02:
      "Sartoria Turrisi is in Ceglie Messapica, a welcoming town in the Province of Brindisi, in the south of Puglia.",
    testo03:
      "Since 1970 our strong sartorial tradition has been handed down from father to son: we are a small artisan company that operates with passion at every stage of production, which is carried out exclusively within our tailor's shop by highly specialized Italian artisans.",
    testo04:
      'The choice of fabrics and accessories of the highest quality allow us to offer the excellence of the authentic "Made in Italy".',
    testo05:
      "Our goal is to satisfy our client in every aspect, putting our long experience at his disposal.",
    testo06:
      "Today, it is possible to confuse a sculptor with a tailor, because both create shapes.",
    testo07: "KARL KRAUS",
    testo08: "FEEL OUR FABRIC SELECTION WITH YOUR HANDS!",
    testo09: "ORDER YOUR FREE KIT NOW!",
  },
  pagina404: {
    errore: "Error 404",
    nonEsiste: "La pàgina que busques no existeix.",
    torna: "torna al configurador",
  },
};
