export const lingua_es = {
  lang: "es",
  title: "3D SHIRT - Configurador 3d para camisas a medida",
  description: "Configurador 3d para camisas a medida",
  personalizza: "Personalizar",
  misure: "Medidas",
  tessuto: "Tejido",
  filtri: "Filtros",
  scegliTessuto: "Elige la tela",
  cercaTessuto: "Buscar tejido...",
  inOfferta: "En oferta",
  nonDisponibile: "No disponible",
  colore: "Color",
  colori: "Colores",
  tuttiColori: "Todos los colores",
  bianco: "Blanco",
  celeste: "Celeste",
  altriColori: "Otros colores",
  disegno: "Dibujo",
  disegni: "Dibujos",
  tuttiDisegni: "Todos los dibujos",
  tintaUnita: "Lisa",
  righe: "Rallas",
  quadretti: "Cuadros",
  fantasia: "Fantasia",
  materiale: "Material",
  materiali: "Materiales",
  tuttiMateriali: "Todos los materiales",
  cotone: "Algodon",
  lino: "Lino",
  brand: "Marca",
  brands: "Marcas",
  tuttiBrands: "Todas las marcas",
  codice: "Codigo",
  ordina: "Ordinar por precio",
  basso: "De màs bajo a màs alto",
  alto: "De màs alto a màs bajo",
  lato: "Vista laterale",
  front: "Vista frontale",
  retro: "Vista retro",
  piegata: "Vista piegata",
  extra: {
    tutti: "Todos los productos",
    selTaglia: "Elige la talla",
    desc: "Descripción",
    correlati: "Productos relacionados",
  },
  bubble: {
    prima: "Clicca qui per tornare alla vista camicia.",
    seconda: "Trascina con il tasto SINISTRO del mouse per ruotare la camicia",
    terza: "Trascina con il tasto DESTRO del mouse per spostare la camicia.",
    quarta: "Muovi la ROTELLINA del mouse per lo zoom.",
    quinta: "OK, CHIUDI",
    sesta: "Trascina con un dito per ruotare la camicia.",
    settima: "Trascina con due dita per spostare la camicia.",
    ottava: "Allarga o stringi con due dita per lo zoom.",
  },
  banner: {
    titolo: "Uso de Cookies",
    testo1:
      "En esta aplicación, utilizamos cookies (o tecnologías similares) para almacenar información en tu ordenador o dispositivo móvil con el fin de mejorar tu experiencia on líne. Las cookies son pequeños archivos de texto que le permiten navegar de manera eficiente, recordar sus preferencias, ayudarnos a comprender el rendimiento de la aplicación y, en general, mejorar su experiencia de usuario.",
    testo2:
      "Al cerrar este mensaje, aceptas el uso de cookies en función de sus (posibles) preferencias.",
    gestione: "Manejo de cookies",
    acconsento: "Estoy de acuerdo",
  },
  sito: {
    titolo: "Sartorie Italiane",
    sottotitolo:
      "De la unión entre tradición y innovación nace SartorieItaliane.it, un avanzado portal de ventas online de camisas a medida. Con un diseño gráfico claro y efectivo, es posible comprar productos de sastrería de alta calidad, hechos totalmente a medida, y entregados directamente en tu domicilio.",
    bottone1: "Crea tu camisa",
    titolo2: "Cómo funciona el configurador",
    sottotitolo2:
      "El configurador de Sartorie Italiane permite una experiencia de configuración fácil, rápida y atractiva.",
    titolo2a: "Recibe nuestras novedades",
    sottotitolo2a:
      "Introduce tu correo electrónico para mantenerte continuamente informado sobre nuestros productos y ofertas",
    configuratore: "Configurador",
    iscriviti: "Regístrate",
    configura: "Configurar",
    titolo3: "Elige la tela",
    sottotitolo3:
      "Selecciona la que prefieras entre una amplia gama de las mejores telas Made in Italy.",
    titolo4: "Calidad de imagen",
    sottotitolo4:
      "Con el uso de un potente software 3D para el diseño de moda, se obtienen imágenes realistas incomparables.",
    titolo5: "Personaliza tu camisa",
    sottotitolo5:
      "Elige los detalles para crear tu camisa ideal y verifica su efecto en tiempo real.",
    titolo6: "Unicidad del producto",
    sottotitolo6:
      " Al personalizar su producto, el usuario tiene la sensación de comprar algo especial y único, hecho especialmente para él.",
    titolo7: "Introduce tus medidas",
    sottotitolo7:
      "Sigue los tutoriales de nuestro configurador para poder tomar tus medidas con mayor precisión y profesionalidad.",
    titolo8: "Producción",
    sottotitolo8:
      "Nuestras camisas vienen producidas exclusivamente en Italia por artesanos expertos, en los talleres más prestigiosos del mundo. Nuestros sastres, verdaderos maestros y cuya sabiduría y habilidad han sido transmitidas de generación en generación, se ocupan de todo lo relacionado con el confeccionamiento, asegurando así la máxima precisión en cada fase del proceso de realización.",
    titolo9: " Web Marketing",
    sottotitolo9:
      "Al brindar una experiencia de navegación inmersiva, el configurador ayuda a aumentar las ventas y el conocimiento de la marca.",
    titolo10: "Características del configurador 3D Shirt",
    sottotitolo10:
      "La aplicación guía al usuario desde la personalización del producto hasta la compra.",
    titolo11: "Personalizacion de la camisa",
    sottotitolo11:
      "A través de pasos simples, será posible configurar la camisa en todos sus aspectos, desde la elección de la tela y los elementos que la componen, hasta la inserción de las medidas.",
    titolo12: "Proceso de compra",
    sottotitolo12:
      "La aplicación puede administrar todas las etapas del proceso de compra, desde la administración del carrito y, por lo tanto, los productos y los códigos de descuento, hasta la administración del envío y el pago.",
    titolo13: "Creación de cuenta",
    sottotitolo13:
      "La aplicación ofrece la posibilidad de poder crear un perfil de usuario que le permitirá almacenar sus mediciones y compras.",
    chisiamo: {
      sotto1:
        "Nos esforzamos en ofrecer a nuestros clientes la posibilidad de elegir su propio estilo con absoluta libertad, asegurando así que recibirán siempre un producto de altísima calidad.",
      p1: "Sartorie Italiane® es una marca del grupo Globalapulia Srl.",
      p2:
        "De la unión entre la tradición y la innovación nace sartorieitaliane.it, un avanzado e-commerce de camisas a medida. Con un diseño gráfico claro y efectivo, es posible comprar productos de sastrería de alta calidad, hechos totalmente a medida y entregados directamente en tu domicilio. Gracias a un sistema de configuración rápido e intuitivo, el usuario tiene la posibilidad de crear su propia camisa, personalizando incluso hasta el mínimo detalle: desde el cuello, color de hilo de los botones, puños e incluso la posibilidad de añadir y colocar las iniciales donde desees. ¡Disfruta personalizando tu camisa a medida!",
      p3:
        "Gracias a nuestro sistema, podemos conservar tu silueta y medidas para que, en un futuro, puedas volver a comprar la misma camisa. En el caso de alguna variación, te recordamos que puedes indicarnos todos los cambios necesarios para satisfacer tus necesidades.",
      p4:
        "El objetivo de Sartorie Italiane es obtener una línea y un corte que se adapten a tu figura y a tus gustos, poniendo a tu disposición una amplia gama de detalles personalizados y una gran experiencia en nuestros artesanos camiseros.",
      p5:
        "Esto es posible gracias a la colaboración con prestigiosos talleres artesanos de sastres italianos, donde la pasión por la moda y lo artesanal pasan de generación en generación.",
        p6:"TEXTA Srl fue fundada en 2014 con el objetivo principal de desarrollar tejidos lisos y estampados con un alto nivel de calidad y valores de estilo. Hoy TEXTA demuestra su liderazgo en el desarrollo de propuestas artísticas de tejidos estampados y lisos para prendas camiseras y de confección de alta gama. TEXTA es un jugador moderno y dinámico que busca estilo, excelencia e innovación TEXTA dispone de un sistema de producción vertical que permite una proyección de calidad total de sus procesos vitales obtenida a través de un equipo de profesionales altamente cualificado. TEXTA destaca el verdadero valor del concepto “Made in Italy”. La selección de materias primas es el hito de nuestra estrategia general, así como el primer paso hacia la oferta de un alto estándar de calidad en cada etapa de nuestro proceso de producción.",
    },
    nostritessuti: {
      sotto1:
        "Elige entre una amplia gama de tejidos, todos ellos producidos por las marcas de mayor prestigio de la industria textil italiana.",
      p1:
        "Para tu camisa podras elegir entre los diferentes tejidos, acabados y combinaciones de color. Nuestra gama de telas y diseños está en constante evolución gracias a los avances y a las tendencias de la industria de la moda y del textil.",
      p2:
        "La historia de Canclini Textiles nasce en 1925, cuando comenzó como industria de la seda en la zona de Como, Italia. En los años 60 y con el primer cambio generacional lleva a la familia, con gran experiencia en el procesamiento de un producto noble y de más alto nivel como la seda, a abandonar al sector de la seda y acercarse al sector del algodón. Esta elección positiva llevó a que en los años posteriores, la venta de tejidos se extendiera a mercados más allá de los Alpes, concretamente en Alemania y Francia. A finales de los 80, la actividad de la empresa recibió un nuevo impulso a partir de la compra de maquinaria moderna. Hoy en día la empresa se encuentra entre las marcas líderes en el campo de los tejidos más finos para las camisas.",
      p3:
        "Albini S.p.A. Es una empresa italiana con sede en Albino (provincia de Bérgamo) que opera desde 1876 en el sector textil, produciendo telas para camisas de gama alta. En 1992, el grupo adquiere dos marcas inglesas, «Thomas Mason» y «David y John Anderson», junto con su archivo histórico compuesto por más de 600 volúmenes de inestimable valor. El proceso de expansión continuó con nuevos centros de producción y la entrada de la marca «Albiate 1830», comprada en el 2000 por la familia Caprotti.",
      p4:
        "Creatividad, innovación y experimentación: estas son las coordenadas que caracterizan el viaje de investigación del que Albiate 1830 es el protagonista absoluto. Gracias a su identidad dinámica y pionera, puede revelar nuevos itinerarios y destinos distintos para la ropa casual contemporánea. En un viaje de exploración que refuerza el vínculo con las tendencias de moda emergentes, sin perder de vista la excepcional tradición de fabricación que brinda hace ya casi dos siglos desde el corazón de la marca. Una esencia que impulsa a los Exploradores Textiles Italianos de Albiate 1830 a crear telas de estilo y calidad sin igual. Con sus bases deportivas, estampados excéntricos, jacquards originales y gran variedad de tonalidades, la marca está dirigida a un público joven con un alma rebelde, que se confirma como el líder indiscutible en ropa de calle de lujo. Albiate 1830 es mucho más que una marca: es la expresión de una investigación creativa única, en la que las materias primas exclusivas, los detalles icónicos y la experimentación dan vida a un inesperado y sorprendente viaje de estilo.",
      p5:
        "Fundada en Lancashire en 1796 por el empresario Thomas Mason, su marca homónima fue un punto de referencia en el mundo de la camiseria inglés por la elegancia y la calidad de sus productos. Adquirida por Albini Grupo en 1992, Thomas Mason reinterpreta su rico patrimonio con un estilo moderno y original, a través de tejidos dobles creados con el lino fino de Normandía y los preciados algodones egipcios Giza 45 y Giza 87.",

      ande:
        "Esta tela gris de Jersey es una elección perfecta para hacer una camisa elegante e informal. Combina con tus blue jeans y una chaqueta casual. Esta tela de jersey está hecha de hilo melange 90/2.",
      squire:
        "Popelina rigurosa construida con 62 hilos 90/2 y 30 beats 60/1. Ofrece una amplia variedad de rayas y cuadrados clásicos y estampados.",
      piave:
        "Tejido de doble torsión en 90/2, estudiado en diferentes tejidos con una amplia gama de colores y estampados.",
      rock: "Print on ovo poplin base.",
      oxford70:
        "Es un tejido creado con hilados muy finos, que permiten obtener el típico efecto punteado del Oxford de forma sofisticada y discreta. Resistente y brillante, Oxford 70, con su maravillosa densidad y tacto suave, es perfecto tanto para la vida de oficina como para los contextos más informales.",
      twill70:
        "Los tejidos Twill 70 son la unión perfecta entre elegancia y versatilidad. La estructura diagonal proporciona a los tejidos twill un aspecto brillante, pero también un tacto suave y una sensación de confort. La definición de los diseños unida a su sencillez hace de Twill 70 el tejido ideal para un perfecto caballero de ciudad.",
      piquet70:
        "Representa la esencia de los tejidos trenzados de Albini. Gracias a la alta densidad de la urdimbre y de la trama, y a la hábil combinación de diferentes estructuras, se caracteriza por nuevos efectos de tridimensionalidad y sofisticados juegos de luz y color.",
      saharaBright:
        "Albini Group solo selecciona el mejor lino europeo para crear tejidos frescos y naturales, con propiedades excepcionales: máxima resistencia, alta capacidad de absorción de la humedad, propiedades aislantes y termorreguladoras y características hipoalergénicas.",
      sJames:
        "Es una antigua popelina inglesa en título 100/2 estructurada de manera espléndida y excepcional por su cuerpo y carácter.",
      duke:
        "Existen muchas diferencias que hacen único al tejido Duke. Clásico y versátil, se realiza con el mejor algodón de fibra extralarga, de forma que resulta altamente resistente al desgaste y a los lavados, pero también es suave, reluciente y fácil de tratar. Una amplia propuesta de estructuras crea nuevos juegos de luz y tridimensionalidad.",
      piumino:
        "Una sensación de agradable ligereza: Piumino está realizado con finísimos hilados únicos de preciado algodón egipcio Giza 87. Un tejido ligero a la vez que compacto, con un carácter profundamente italiano, caracterizado por su brillo, resistencia y elegancia. Gracias a su tacto sedoso, se adapta al cuerpo como una segunda piel.",
      bruges:
        "A partir de la unión del mejor algodón de fibra larga y del lino europeo más preciado, nacen sofisticados tejidos de lino mixto que combinan las mejores características de estas dos fibras naturales: el tacto agradable y la resistencia típica del algodón con la frescura y creatividad del lino.",
      superPiumino:
        "Es un tejido sedoso y compacto, realizado con finísimos hilados únicos de preciado algodón egipcio Giza 87. Gracias a su rica base, Super Piumino se presenta en diferentes trenzados para adaptarse cada día a un estilo elegante o informal.",
      silver:
        "Silver, la pepelina de color tejida de título 100/2, cuyo nombre es sinónimo de perfección, blancura reluciente y pureza. Silver representa la clásica camisa inglesa a rayas y a cuadros, con un tacto sin igual, un color vibrante y su gran durabilidad.",
      comfort:
        "Es un tejido elástico, compacto y luminoso que garantiza una vestibilidad ideal para un estilo de vida dinámico y contemporáneo y una elasticidad permanente incluso con el paso del tiempo. En trama, el hilado de elastómero se recubre de algodón, ofreciendo una sensación natural sobre la piel.",
      portland:
        "Es una refinada popelina de color tejida en urdimbre y trama superfinas 120/2, caracterizada por su fina elegancia, definición de colores y atractivo atemporal, junto con un excepcional tacto suave a la vez que áspero sorprendentemente duradero.",
      victoriaFlam:
        "Es un tejido denim caracterizado por interesantes toques flameados que le confieren un aspecto vintage y natural.",
      chambray:
        "Ligero y transpirable, el Chambray es un tejido con una tacto suave y fresco, ideal para la temporada de verano. El hábil uso de hilos con efecto flameado realza su aspecto alegre y desenfadado. Extremadamente versátil, pero siempre refinado, el Chambray se adapta perfectamente a cualquier ocasión.",
      buckingham:
        "Un clásico de elegancia y finura, Buckingham es una popelina de título 140/2 y lleva el nombre de la gran residencia oficial de su majestad la Reina Isabel II.",
      zephir:
        "Debido al mismo número de hilos en la trama y que en la urdimbre, este tejido de algodón vichy finísimo, ligero y delicado como el viento de ponente, adquiere una gran armonía.",
      royalOxford:
        "A menudo imitado, pero nunca superado, el Royal Oxford es un bonito tejido con una distintiva y significativa textura. La combinación de color, brillo, hilos dobles junto con la larga tradición del Oxford contribuyen al espíritu de este excepcional tejido, que es extremadamente versátil y se adapta tanto a ocasiones formales como informales.",
      journey:
        "La colección Journey de Thomas Mason presenta tejidos innovadores ideales para aquellos que buscan una camisa siempre perfecta y sin arrugas, incluso después de salir de la maleta. Gracias a su innovador acabado, estos tejidos son resistentes a las arrugas y no requieren ningún cuidado especial para mantener su aspecto en orden.",
      balmoral:
        "Es un tejido precioso que combina la suavidad y el calor del cachemir con la transpirabilidad y el confort del algodón. El resultado es un tejido ligero, con un tacto agradable y suave ideal para una camisa informal distinguida y elegante, bonita estéticamente, de fácil cuidado y con un confort único.",
    },
    nostriartigiani: {
      sotto1:
        "Las camisas de Sartorie Italiane se producen exclusivamente en Italia, gracias a los sastres con los que trabajamos y en los talleres artesanales más prestigiosos y apreciados del mundo.",
      p1:
        "Nuestros sastres son verdaderos maestros cuya sabiduría y habilidad pasan de generación en generación. Ellos se ocupan de todo el proceso de la confección, de esta manera está garantizada la máxima precisión en cada fase del proyecto de fabricación. Mediante la unión de los tejidos, se crean costuras de gran nivel que hacen que sean más duraderas en el tiempo, utilizando únicamente hilo, accesorios y componentes italianos de primera calidad.",
      p2:
        "Cada fase del proceso se somete a estrictos controles de calidad que van desde el diseño, corte de telas hasta pasar por las costuras.",
      p3:
        "En definitiva, una combinación de artesanía italiana que hacen que los detalles marquen la diferencia.",
      t4: "Ojales e hilos",
      p4:
        "Al igual que todos los detalles de nuestras camisas, los ojales y los hilos que aseguran el botón se pueden personalizar a tu gusto, ya que pueden escogerse entre una amplia gama de colores.",
      t5: "Botones",
      p5:
        "Nuestros botones vienen aplicados con el método Ascolite®, es decir posteriormente a que se hayan cosido estos se someten a un proceso patentado que hacen que el botón tenga una gran flexibilidad y resistencia en el tiempo.",
      t6: "Costura",
      p6:
        "La costura de nuestras camisas se realizan a mano. Con cierre 5mm a una aguja, robusta y valiosa, tiene una resistencia superior a la industrial.",
      t7: "Mosca",
      p7:
        "La mosca es un pequeño triangulo de tejido cosido en cada lado por delante y por detrás de la camisa. Tiene el objetivo de dar fuerza a la costura y evitar que se rompa.  Está presente en todas nuestras prendas y se puede puede personalizar según se desee, ya sea en cifras o logos.",
      t8: "Espesor cuello y punos",
      p8:
        "Nuestros artesanos realizarán las camisas con cuellos y puños de espesor medio. Los espesores personalizados de diferente dureza (Ultra Ligero, Ligero, Medio, Pesado, Muy pesado) y podrán escogerse, a petición del cliente, sin ningún incremento del precio final a petición del cliente. Tal vez no todo el mundo lo sabe o nunca han prestado demasiada atención, pero las partes de la camisa, es decir, todos los elementos de sastrería necesarios para su realización, tiene un nombre preciso que permanece invariable en el tiempo.",
      p9:
        "Según los artesanos camiseros, a pesar de que con la producción industrial y la evolución de la moda algunos de estos elementos han desaparecido, una camisa de calidad no puede llamarse “camisa” sin el conjunto de todas las partes que lo forman. Para entender la complejidad del trabajo del artesano trataremos de separar y reconocer, una por una, todas las partes más importantes de la camisa, así como entrando en detalle de todo lo que determina la calidad:",
      t10: "Delante",
      p10:
        "Se trata exactamente de la parte frontal de una camisa, o mejor de las dos partes que lo componen, donde vienen aplicados los botones y a veces los bolsillos.",
      t11: "Bolsillo",
      p11:
        "El bolsillo se cose generalmente en el lado izquierdo de la parte frontal. Es un detalle que suele aparecer en las camisas mas de sport, especialmente si viene realizada con un cuello button down, y en las camisas de trabajo, donde tiene una función de utilidad y decorativa.",
      t12: "Cañón",
      p12:
        "Se trata de la banda en la que vienen cortadas las ranuras. El cañón cosido «a vista» es típico de las camisas de deporte, mientras que en las camisas elegantes y clasicas no aparecen.",
      t13: "Puño",
      p13:
        "La forma del puño varía según el tipo de camisa. En los modelos clásicos el puño tiene las puntas redondeadas, además de presentar pequeños pliegues entre la manga y el punto de unión, sobretodo lo podemos observar en el caso del puño cuando va con gemelos. Sin embargo, en los modelos sport, el puño es recto y en este caso, la apertura a lo largo de la manga que sigue el puño es la tapeta de la manga, que normalmente se cierra con un botón.",
      t14: "Costado",
      p14:
        "Desde la sisa y siguiendo todo el costado se puede deducir fácilmente la calidad de fabricación de una camisa. De hecho, cuanto mayor sea la densidad de los puntos de las costuras, mayor calidad tendrá la camisa: de 8/9 puntos por centímetro querrá decir que se trata de una camisa excelente.",
      t15: "Dobladillo",
      p15:
        "Es el acabado realizado en la parte inferior de la camisa, variable según el modelo y el fabricante.",
      t16: "Botones",
      p16:
        "Los botones también representan una marca de distinción para una camisa de calidad. En las camisas elegantes, deben tener un espesor de al menos 2/3 mm, y preferentemente son de nácar. Aconsejamos siempre que detrás del cañón vayan cosidos al menos un par de repuesto.",
      t17: "Sisas",
      p17:
        "Es el punto en el que se inserta la manga, en las camisas de calidad viene siempre cosido dos veces para dar así mayor resistencia.",
      t18: "Carré",
      p18:
        "Es la parte de la camisa que cubre los hombros. En las camisas de calidad está compuesto por una pieza separada del resto de la camisa, de doble tela y cortado en vertical.",
      t19: "Cañón y espalda",
      p19:
        "Esta es la parte de detrás de la camisa, confeccionada de forma diferente según el modelo. Puede ser liso, con un cañón central, con dos pliegues laterales o con pinzas en el caso de las camisas entalladas que siguen la forma del cuerpo.",
      t20: "Cuello",
      p20:
        "El cuello también se distingue según el patrón y el estilo de la camisa. Los cuellos más conocidos son el italiano, francés, button down y el fijado por los característicos botones. En las camisas elegantes el cuello se mantiene rígido por la inserción de listones en metal, hueso o material plástico. * Recomendamos extraer los listones durante la fase de lavado y planchado de la camisa.",
      t21: "Mosca",
      p21:
        "La mosca es un pequeño triángulo de tela cosido en los laterales de la camisa, ubicado exactamente en el punto medio entre el frente y la parte posterior de ésta. Sirve para reforzar la costura y evitar que la camisa se rompa.",
    },
  },
  contact: {
    titolo: "Envianos un mensaje",
    sottotitolo:
      "Contáctanos para solicitar información o simplemente para preguntas o sugerencias relacionadas con tu pedido. Intentaremos responderte lo antes posible.",
    nome: "Tu nombre",
    telefono: "Tu teléfono",
    email: "Escribe tu e-mail",
    messaggio: "Escribe tu mensaje",
    invia: "Envía tu mensaje",
  },
  header: {
    account: "Cuenta",
    modificapassword: "Cambiar Contraseña",
    logout: "Logout",
    wishlist: "Lista de Deseos",
    shopping: "Cesta de Compra",
    loggedOut: "Ya no estás conectado.",
    loggedOutError: "Ha ocurrido un error:",
    cambiaPasswordSuccess:
      "Le enviaremos un correo electrónico con un enlace único para restablecer su contraseña.",
    cambiaPasswordError: "Ha ocurrido el siguiente error:",
  },
  coloriTip: {
    grigio: "Gris",
    blu: "Azul",
    nero: "Negro",
    marrone: "Marrón",
    verde: "Verde",
    bianco: "Blanco",
    viola: "Viola",
    arancione: "Naranja",
    beige: "Beige",
    giallo: "Amarillo",
    rosa: "Rosa",
    rosso: "Rojo",
  },
  footer: {
    account: "Account",
    login: "Login",
    logout: "Logout",
    cookie: "Política de cookies",
    privacy: "Política de privacidad",
    termini: "Términos y Condiciones",
    recesso: "Derecho de Anular",
    contattaci: "Contacto",
    partita: "P.IVA",
    about: "Quiénes somos",
    ourfabrics: "Nuestras telas",
    ourartisans: "Nuestros artesanos",
    famiglie: "Descripción familia tejidos",
  },
  dettaglio: {
    descrizione: "Descripción del tejido",
    comeMisure: "Cómo tomar medididas",
    spedizione: "Envío y devolución",
  },
  navigazione: {
    lang: "es",
    tessuto: "Tejido",
    scegliTessuto: "Elige el tejido",
    inOfferta: "En oferta",
    nonDisponibile: "No disponible",
    personalizza: "Personalizar",
    personalizzazione: "Personalización",
    polsinoGemelli: "Puño gemelos",
    polsinoDU: "Gemelos doble uso",
    infoDU: "Adapto tambien para gemelos",
    contrastoColletto: "Aplicar al cuello.",
    contrastoPolsino: "Aplicar al puño",
    contrastoChiusura: "Aplicar al cierre",
    chiusura: "Cierre",
    colletto: "Cuello",
    polsino: "Puño",
    taschino: "Bolsillos",
    pinces: "Detrás",
    asole: "Ojal",
    bottoni: "Botons",
    fili: "Hilo",
    contrasto: "Contraste",
    loading: "Cargando...",
    mascherina: "Mascarilla",
    maniche: "Mangas",
    info1: "Slim Fit only",
    iniziali: {
      iniziali: "Iniciales",
      inserisciIniziali: "Escribe tus Iniciales",
      tueIniziali: "Entrez vos Iniciales",
      stile: "Estilo Iniciales",
      colore: "Color Iniciales",
      bianco: "Blanco",
      blu: "Azul",
      celeste: "Celeste",
      grigio: "Gris",
      nero: "Negro",
      rosso: "Rojo",
      verde: "Verde",
      arancione: "Naranja",
      rosa: "Rosada",
      posizione: "Posición iniciales",
      sopra: "Arriba",
      centro: "Centro",
      sotto: "Puño",
    },
    misure: {
      uomo: "Hombre",
      donna: "Mujer",
      bambino: "Niño",
      metodoTitolo: "Selecciona tu método",
      metodoTaglia: "Mediciones de tallas estándar",
      metodoCorpo: "Mide tu cuerpo",
      metodoCamicia: "Mide una camisa que te quede bien",
      metodoInvia: "Envíanos una camisa para medirla",
      metodoModifica: "Editar",
      metodoInformazioni: "Información",
      selezionaTaglia: "Selecciona tu talla...",
      selezionaTagliaBambino: "Talla niño",
      anni: "Años",
      anno: "Año",
      polo: "Estilo Polo",
      vestito: "Vestido",
      selezionaVestibilita: "Selecciona tu ajuste...",
      misure: "Medidas",
      tueMisure: "Tus medidas",
      salva: "Guardar tus medidas",
      errore: "Error al cargar",
      successo: "Medidas cargadas con éxito",
      Braccia: "Brazos",
      Collo: "Cuello",
      Girovita: "Cintura",
      Polso: "Puño",
      Spalle: "Hombros",
      Torace: "Torax",
      Lunghezza: "Longitud",
      vuoiAiuto: "Quieres ayuda con las medidas?",
      provaSelezionare: "Seleccionar tu talla",
      successoCarica: "Medidas cargadas con éxito",
      seleziona: "Seleccionar...",
      xs: "XS (Cuello 38)",
      s: "S (Cuello 38)",
      m: "M (Cuello 39/40)",
      l: "L (Cuello 41/42)",
      xl: "XL (Cuello 43/44)",
      xxl: "XXL (Cuello 45/46)",
      xxxl: "XXXL (Cuello 47/48)",
      oppure: "o",
      stoCaricando: "Cargando...",
      carica: "Sube tus medidas",
      inviaTitolo: "Envíanos la camisa a medir",
      inviaParag1:
        "¿Desea duplicar las medidas de una camisa en su posesión, pero no sabe cómo medirla correctamente?",
      inviaParag2:
        "Envíenos su camisa y nuestros expertos la medirán y guardarán sus medidas para futuras compras. * Tenga en cuenta que no se dañará su camisa y se la enviaremos de la mejor manera posible.",
      inviaParag3: "Enviar a:",
      inviaTitolo2: "Al enviar, asegúrese de incluir:",
      inviaLista1: "La camisa que quieres que midamos",
      inviaLista2: "Tu nombre",
      inviaLista3: "Un número de teléfono",
      inviaLista4: "Su dirección de correo electrónico",
      inviaLista5: "La dirección de envío",
      inviaLista6: "Cualquier instrucción especial",
      infoTipoMisure: "Tipo de medidas:",
      infoTipoMisure0: "medidas por talla",
      infoTipoMisure1: "medidas del cuerpo",
      infoTipoMisure2: "medidas de la camisa",
      infoTipoMisure3: "Enviaré una camisa a medida",
      infoTagliaSelezionata: "Talla seleccionada:",
      infoVestibilita: "Ajuste",
      aggiungiIniziali: "Agregar iniciales",
      stile1: "estilo",
      colore1: "colore",
      posizione1: "ubicación",

      tabellaTaglie: "Guía de tallas",
      slimDonna: "CAMISA SLIM MUJER",
      vestitoslimDonna: "VESTIDO SLIM MUJER",
      regularDonna: "CAMISA REGULAR MUJER",
      vestitoregularDonna: "VESTIDO REGULAR MUJER",
      boyfriendDonna: "CAMISA BOY-FRIEND MUJER",
      poloDonna: "CAMISA POLO MUJER",
      camiciaUomo: "CAMISA SLIM, REGULAR Y ESTILO POLO HOMBRE",
      camiciaBambino: "CAMISA SLIM, REGULAR Y ESTILO POLO NIŇO",
      small: "SMALL",
      medium: "MEDIUM",
      large: "LARGE",
      extralarge: "EXTRALARGE",
      Vita: "CINTURA",
      Manica: "BRAZOS",
      statura: "ALTURA",
    },
  },
  barraAcquisto: {
    lang: "es",
    nonDisponibile: "Tejido no disponible.",
    tooltip: "Añadir esta camisa a la Cesta de Compra",
    carrello: "Añadir a la Cesta de Compra",
    camicia: "Camisa ",
    tessuto: "Tejido ",
    di: " de",
    aggiunta: "Camisa añadida a la Cesta de Compra",
    aggiunta1: "Camisa añadida con éxito a la Cesta de Compra.",
    aggiunta2: "¿Qué quieres hacer ahora?",
    continua: "Continúa con tus compras",
    vai: "Ir a la Cesta de Compra",
    wishlist: {
      lang: "es",
      nuovoPrezzo: "Nuevo precio",
      nonDisponibile: "Atención, tejido no disponible!",
      wishlist: "Lista de Deseos",
      vuota: "Tu lista de deseos está vacía.",
      Camicia: "Camisa",
      tooltip: "Agregar la camisa a la Wishlist",
      successo: "Camiseta agregada con éxito a la Wishlist.",
      rimossa: "Camiseta eliminada con éxito de la Wishlist.",
      Prodotto: "Producto",
      Prezzo: "Precio",
      Descrizione: "Descripción",
      Tessuto: "Tela",
      Disegno: "Dibujo",
      Colletto: "Cuello",
      Italiano: "Italiano",
      "Italiano Classico": "Italiano clásico",
      "Francese Classico": "Francés clásico",
      "Francese Semiaperto": "Semi-abierto francés",
      "Francese Semiaperto due bottoni": "Semi-abierto frances dos botones",
      "Botton Down Classico": "Button Down",
      "Botton Down alto due bottoni": "Button Down alto dos botones",
      Diplomatico: "Diplomático",
      Coreano: "Coreano",
      "Polsino arrotondato": "Puño redondeado",
      "Polsino smussato ": "Puño biselado",
      "Polsino gemelli": "Puño gemelos",
      "Manica corta": "Manga corta",
      Chiusura: "Cierre",
      "Faldoncino interno non Impunturato": "francés",
      "Faldoncino Esterno Impunturato": "Estándar",
      "Finta Doppia e bottoni nascosti": "Oculto",
      "Nessun Taschino": "No bolsillo",
      "Taschino arrotondato": "Bolsillo redondeado",
      "Taschino smussato": "Bolsillo biselado",
      "Dos taschini arrotondati": "Dos bolsillos redondeados",
      "Due taschini smussati": "Dos bolsollos biselados",
      "Dietro liscio": "Detrás liso",
      "Dietro con doppie pinces": "Detrás con pinzas dobles",
      "Dietro con doppia piega": "Detrás con pinza central",
      "Asola bianca": "Ojal blanco",
      "Asola blu": "Ojal azul",
      "Asola celeste": "Ojal celeste",
      "Asola grigia": "Ojal gris",
      "Asola nera ": "Ojal negro",
      "Asola rossa": "Ojal rojo",
      "Bottone bianco": "Botones blanco",
      "Bottone blu": "Botones azul",
      "Bottone celeste": "Botones celeste",
      "Bottone grigio": "Botones gris",
      "Bottone nero": "Botones nero",
      "Bottone rosso": "Botones rojo",
      "Filo bianco": "Hilo blanco",
      "Filo blu": "Hilo azul",
      "Filo celeste": "Hilo celeste",
      "Filo grigio": "Hilo gris",
      "Filo nero": "Hilo negro",
      "Filo rosso": "Hilo rojo",
      Contrasto: "Contraste",
      contrastoPolsino: "Puño",
      contrastoColletto: "Cuello",
      contrastoChiusura: "Cierre",
      Stile: "Estilo",
      Iniziali: "Iniciales",
      iniStile: "Estilo",
      iniColore: "Color",
      iniPosizione: "Posición",
      white: "Blanco",
      slateblue: "Azul",
      lightsteelblue: "Celeste",
      lightgray: "Grey",
      black: "Negro",
      crimson: "Rojo",
      posizione: "Elegir posición",
      sopra: "Arriba",
      centro: "Centro",
      sotto: "Abajo",
      rimuovi: "Eliminar de la Wishlist",
      importa: "Importar en el configurador",
      torna: "Volver al configurador",
    },
  },
  tutorial: {
    braccia: {
      header: "Brazos",
      titolo: "Medidas de los Brazos",
      descrizione:
        "Medir la manga desde el principio del hombro hasta la muñeca doblando el brazo a 90°. Para la medición exacta, es necesario medir desde el hombro hasta el codo y continuar hasta el final de la muñeca, donde se une con la mano.",
    },
    bracciaCamicia: {
      header: "Brazos",
      titolo: "Medidas de los Brazos",
      descrizione:
        "Mida la manga desde la costura más alta del hombro hasta el extremo del puño, manteniendo la camisa plana sobre una superficie plana.",
    },
    collo: {
      header: "Cuello",
      titolo: "Medidas del cuello",
      descrizione:
        "Medir inmediatamente debajo de la manzana de Adán, deslice la cinta alrededor del cuello para tomar la circunferencia, es importante insertar un dedo entre la cinta métrica y el cuello para garantizar un ajuste cómodo",
    },
    colloCamicia: {
      header: "Cuello",
      titolo: "Medidas del cuello",
      descrizione:
        "Mida la distancia lineal entre el botón del collar y el ojal correspondiente, manteniendo el collar abierto y acostado sobre una superficie plana.",
    },
    girovita: {
      header: "Cintura",
      titolo: "Medida de cintura",
      descrizione:
        "Medir la circunferencia de la cintura en el punto más ancho a la altura del ombligo; si tiene caderas anchas, es recomendable tomar la medida un poco más abajo para comprenderlas en su totalidad. Para un ajuste correcto, el metro debe adherir a la cintura dejando un dedo de tolerancia entre el cuerpo y el metro, para poder sentarse cómodamente. Es importante tomar la medida precisa, manteniendo el metro adherido al cuerpo: la portabilidad estará dada por el modelo elegido.",
    },
    girovitaCamicia: {
      header: "Cintura",
      titolo: "Medida de cintura",
      descrizione:
        "Mida la cintura en el punto más ancho del ombligo, entre el segundo y el tercer botón desde la parte inferior, manteniendo la camisa plana sobre una superficie plana. Si tiene caderas anchas, es recomendable tomar la medida un poco más abajo. para entenderlos en total.",
    },
    polso: {
      header: "Puño",
      titolo: "Medidas del puño",
      descrizione:
        "Medir la circunferencia de la muñeca a la altura de la articulación Para una correcta portabilidad, el metro debe adherirse a la muñeca mientras mantiene un dedo de tolerancia entre la muñeca y el metro.",
    },
    polsoCamicia: {
      header: "Puño",
      titolo: "Medidas del puño",
      descrizione:
        "Mida la distancia lineal entre el botón del puño y el ojal correspondiente, manteniendo el puño abierto y acostado sobre una superficie plana.",
    },
    spalle: {
      header: "Hombros",
      titolo: "Medidas de los hombros",
      descrizione: "Medir el ancho de los hombros en el punto más ancho.",
    },
    spalleCamicia: {
      header: "Hombros",
      titolo: "Medidas de los hombros",
      descrizione:
        "Mida los hombros en el punto más ancho, desde la costura superior del brazo izquierdo hasta la del brazo derecho, manteniendo la camisa plana sobre una superficie plana.",
    },
    torace: {
      header: "Torax",
      titolo: "Medidas del torax",
      descrizione:
        "Medir la circunferencia del torax al nivel del pecho. Para un ajuste correcto, debe adherir el metro al pecho, siempre manteniendo un dedo de tolerancia entre el cuerpo y el metro para que pueda moverse con libertad.",
    },
    toraceCamicia: {
      header: "Torax",
      titolo: "Medidas del torax",
      descrizione:
        "Mida la circunferencia del pecho entre el segundo y el tercer botón desde arriba. Mida la distancia lineal entre el botón y el ojal correspondiente opuesto.",
    },
    lunghezza: {
      header: "Longitud",
      titolo: "Medidas de longitud",
      descrizione:
        "Incline su cabeza hacia adelante y mida desde el centro de la base posterior del cuello, hasta 3/4 de las nalgas para una longitud clásica. Si usa una camisa, mida la parte posterior desde el centro de la costura del cuello hasta el dobladillo inferior.",
    },
    lunghezzaCamicia: {
      header: "Longitud",
      titolo: "Medidas de longitud",
      descrizione:
        "Manteniendo la camisa plana sobre una superficie plana, mida desde el centro de la base posterior del cuello, en el centro de la costura entre la camisa y el cuello, hasta el dobladillo inferior.",
    },
  },
  contatti: {
    richiesto: "Campo obligatorio",
    emailInvalida: "Dirección de correo electrónico no válida",
    titolo: "Nos encanta hablar contigo",
    successo: "Gracias. Su mensaje ha sido enviado con éxito.",
    errore: "Cuidado. Ha ocurrido un error.",
    nome: "Nombre *",
    email: "Email *",
    oggetto: "Sujeto *",
    messaggio: "Mensaje *",
    attendere: "Esperar...",
    invia: "Enviar",
    testo01:
      "CADA PEDIDO REQUIERE AL MENOS 20 DÍAS LABORABLES ANTES DEL ENVÍO. Nos disculpamos por esta molestia, pero su camisa no será como todas las demás.",
    testo02:
      "Sartoria Turrisi se encuentra en Ceglie Messapica, una ciudad acogedora en la provincia de Brindisi, en el sur de Puglia.",
    testo03:
      "Desde 1970, nuestra sólida tradición de vestimenta se ha transmitido de padres a hijos: somos una pequeña empresa artesanal que opera con pasión en cada etapa de la producción, que se lleva a cabo exclusivamente en nuestra sastrería por artesanos italianos altamente especializados.",
    testo04:
      'La elección de telas y accesorios de la más alta calidad nos permite ofrecer la excelencia del auténtico "Made in Italy".',
    testo05:
      "Nuestro objetivo es satisfacer a nuestros clientes en todos los aspectos, poniendo nuestra larga experiencia a su disposición.",
    testo06:
      "Hoy es posible confundir a un escultor con un sastre, porque ambos crean formas.",
    testo07: "KARL KRAUS",
    testo08: "Puedes apreciar la calidad de nuestros tejidos con TUS MANOS¡",
    testo09: "PIDE TU KIT GRATIS AHORA!",
  },
  pagina404: {
    errore: "Error 404",
    nonEsiste: "¡La página que buscas no existe!",
    torna: "Volver al configurador",
  },
};
