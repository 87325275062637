export const prices = {
  club: 0,
  contrastoColletto: 0,
  contrastoPolsino: 0,
  contrastoChiusura: 0,
  iniziali: 0,
  mascherina: 6,
};

export const owner = {
  lang: "it",
  appName: "3D Shirt",
  appUrl: "https://sartorie-italiane.netlify.app",
  azienda: "Globalapulia s.r.l.",
  attenzione: "Luigi De Francesco",
  indirizzo: "Via Di Palma, 82",
  citta: "Taranto",
  provincia: "TA",
  cap: "74123",
  email: "info@sartorieitaliane.it",
  telefono: "+39 099 4525010",
  piva: "02911930739",
  registroDi: "Taranto",
  foro: "Taranto",
  gdpr: {
    titolare: "Luigi De Francesco",
    email: "info@sartorieitaliane.it",
    data: "07 Gennaio 2021",
  },
  social: {
    facebook: "",
    twitter: "",
    whatsapp: "https://api.whatsapp.com/send?phone=34663289088",
  },
};
