export const initialState = {
  shop: {
    lingua: "",
    scroll: 0,
    vista: "intera",
    rotazione: true,
    cameraOrbit: [0, 0, 55],
    fieldOfView: 55,
    customization: "tessuti",
    personalizzazione: "colletti",
    search: "",
    openMain: "",
    filtri: false,
    filtriPersonalizza: false,
    colore: "",
    disegno: "",
    materiale: "",
    brand: "",
    flagIntera: true, //disabilitato
    flagLegenda: true, //disabilitato
    camicia: {
      colletto: 0,
      polsino: 0,
      doppioUso: false,
      mask: false,
      club: false,
      chiusura: 0,
      taschino: 0,
      contrasto: 0,
      contrastoColletto: true,
      contrastoPolsino: true,
      contrastoChiusura: true,
      asola: -1,
      bottone: 0,
      filo: 0,
      elastico: 0,
      pince: 0,
      iniziali: {
        attiva: false,
        testo: "",
        stile: "block",
        colore: 0,
        posizione: 0,
      },
      misure: {
        genere: 0, //0=uomo, 1=bambino, 2=donna
        taglia: "41",
        tipo: 0, // 0=taglia 1=corpo 2=camicia 3=invio
        fit: "Slim",
        fitDonna: "Slim",
        braccia: 66,
        collo: 41.5,
        girovita: 102,
        lunghezza: 79,
        polso: 23,
        spalle: 48,
        torace: 104,
      },
    },
  },
};
