// prefer default export if available
const preferDefault = m => (m && m.default) || m

exports.components = {
  "component---cache-caches-gatsby-plugin-offline-app-shell-js": () => import("./../../caches/gatsby-plugin-offline/app-shell.js" /* webpackChunkName: "component---cache-caches-gatsby-plugin-offline-app-shell-js" */),
  "component---src-pages-404-js": () => import("./../../../src/pages/404.js" /* webpackChunkName: "component---src-pages-404-js" */),
  "component---src-pages-about-us-js": () => import("./../../../src/pages/about-us.js" /* webpackChunkName: "component---src-pages-about-us-js" */),
  "component---src-pages-contact-js": () => import("./../../../src/pages/contact.js" /* webpackChunkName: "component---src-pages-contact-js" */),
  "component---src-pages-cookie-policy-js": () => import("./../../../src/pages/cookie-policy.js" /* webpackChunkName: "component---src-pages-cookie-policy-js" */),
  "component---src-pages-fabrics-family-js": () => import("./../../../src/pages/fabrics-family.js" /* webpackChunkName: "component---src-pages-fabrics-family-js" */),
  "component---src-pages-index-js": () => import("./../../../src/pages/index.js" /* webpackChunkName: "component---src-pages-index-js" */),
  "component---src-pages-our-artisans-js": () => import("./../../../src/pages/our-artisans.js" /* webpackChunkName: "component---src-pages-our-artisans-js" */),
  "component---src-pages-our-fabrics-js": () => import("./../../../src/pages/our-fabrics.js" /* webpackChunkName: "component---src-pages-our-fabrics-js" */),
  "component---src-pages-privacy-policy-js": () => import("./../../../src/pages/privacy-policy.js" /* webpackChunkName: "component---src-pages-privacy-policy-js" */),
  "component---src-pages-sizes-tables-js": () => import("./../../../src/pages/sizes-tables.js" /* webpackChunkName: "component---src-pages-sizes-tables-js" */),
  "component---src-pages-terms-js": () => import("./../../../src/pages/terms.js" /* webpackChunkName: "component---src-pages-terms-js" */),
  "component---src-pages-withdrawal-js": () => import("./../../../src/pages/withdrawal.js" /* webpackChunkName: "component---src-pages-withdrawal-js" */),
  "component---src-templates-articolo-js": () => import("./../../../src/templates/articolo.js" /* webpackChunkName: "component---src-templates-articolo-js" */),
  "component---src-templates-blog-js": () => import("./../../../src/templates/blog.js" /* webpackChunkName: "component---src-templates-blog-js" */),
  "component---src-templates-camicia-js": () => import("./../../../src/templates/camicia.js" /* webpackChunkName: "component---src-templates-camicia-js" */),
  "component---src-templates-categoria-js": () => import("./../../../src/templates/categoria.js" /* webpackChunkName: "component---src-templates-categoria-js" */),
  "component---src-templates-prodotto-js": () => import("./../../../src/templates/prodotto.js" /* webpackChunkName: "component---src-templates-prodotto-js" */),
  "component---src-templates-shop-js": () => import("./../../../src/templates/shop.js" /* webpackChunkName: "component---src-templates-shop-js" */)
}

