export const lingua_en = {
  lang: "en",
  title: "3D SHIRT - Customized shirts 3d configurator",
  description: "Customized shirts 3d configurator",
  personalizza: "Customization",
  misure: "Measurements",
  tessuto: "Fabric",
  filtri: "Filters",
  scegliTessuto: "Select the fabric",
  cercaTessuto: "Search fabric...",
  inOfferta: "On sale",
  nonDisponibile: "Not available",
  colore: "Color",
  colori: "Colors",
  tuttiColori: "All colors",
  bianco: "White",
  celeste: "Light blue",
  altriColori: "Other colors",
  disegno: "Drawing",
  disegni: "Drawings",
  tuttiDisegni: "All drawings",
  tintaUnita: "Solid color",
  righe: "Lines",
  quadretti: "Squares",
  fantasia: "Printed",
  materiale: "Fabric",
  materiali: "Fabrics",
  tuttiMateriali: "All fabrics",
  cotone: "Cotton",
  lino: "Linen",
  brand: "Brand",
  brands: "Brands",
  tuttiBrands: "All brands",
  codice: "Code",
  ordina: "Sort by price",
  basso: "Low to high",
  alto: "High to low",
  lato: "Side view",
  front: "Front view",
  retro: "Back view",
  piegata: "Folded view",
  extra: {
    tutti: "All products",
    selTaglia: "Select size",
    desc: "Description",
    correlati: "Related products",
  },
  bubble: {
    prima: "Clicca qui per tornare alla vista camicia.",
    seconda: "Trascina con il tasto SINISTRO del mouse per ruotare la camicia",
    terza: "Trascina con il tasto DESTRO del mouse per spostare la camicia.",
    quarta: "Muovi la ROTELLINA del mouse per lo zoom.",
    quinta: "OK, CHIUDI",
    sesta: "Trascina con un dito per ruotare la camicia.",
    settima: "Trascina con due dita per spostare la camicia.",
    ottava: "Allarga o stringi con due dita per lo zoom.",
  },
  banner: {
    titolo: "Use of Cookies",
    testo1:
      "In this application, we use cookies (or similar technologies) to store information on your computer or mobile device in order to improve your online experience. Cookies are small text files that allow you to navigate efficiently, remember your preferences, help us understand the performance of the application and generally improve your user experience.",
    testo2:
      "By closing this message you consent to the use of cookies based on your (possible) preferences.",
    gestione: "Cookies management",
    acconsento: "I agree",
  },
  sito: {
    titolo: "Sartorie Italiane",
    sottotitolo:
      "Combining tradition and innovation, SartorieItaliane.it is an up-to-date website dedicated solely to the sale of custom-made shirts. With a clear and effective graphic design, you can design and purchase high-quality tailor made products, delivered directly to your door.",
    bottone1: "Create your shirt",
    titolo2: "How the configurator works",
    sottotitolo2:
      "The Sartorie Italiane configurator allows for an easy, fast and engaging configuration experience.",
    titolo2a: "Receive our news",
    sottotitolo2a:
      "Enter your email to stay updated on all our new products and offers",
    configuratore: "Configurator",
    configura: "Configura",
    iscriviti: "Subscribe",
    titolo3: "Choose the fabric",
    sottotitolo3:
      "Select the one you prefer, from a wide range of fine fabrics Made in Italy.",
    sottotitolo4:
      "Con l’utilizzo di potentissimi software di progettazione 3D per il fashion design, si ottengono immagini realistiche senza eguali.",
    titolo5: "Personalize your shirt",
    sottotitolo5:
      "Choose the details to create your unique shirt and check its effect in real time.",
    sottotitolo6:
      " Personalizzando il proprio prodotto, l’utente ha la sensazione di comprare qualcosa di speciale e unico, fatto apposta per lui.",
    titolo7: "Enter the measurements",
    sottotitolo7:
      "Follow the tutorials in order to take your measurements accurately and professionally.",
    titolo8: "Production",
    sottotitolo8:
      "Our shirts are produced exclusively in Italy by skilled artisans, in one of the most prestigious tailor’s workshops in the world. Our expert tailors, true masters whose wisdom and skill has been handed down for generations, dealing with everything related packaging, thus ensuring maximum precision in every phase of the realization process.",
    titolo9: " Web Marketing",
    sottotitolo9:
      "Regalando un’esperienza di navigazione coinvolgente, il configuratore aiuta a far crescere le vendite e la notorietà del Brand.",
    titolo10: "Caratteristiche del configuratore di Sartorie Italiane",
    sottotitolo10:
      "L'applicazione guida l'utente dalla personalizzazione del prodotto, fino all'acquisto.",
    titolo11: "Personalizzazione Camicia",
    sottotitolo11:
      "Attraverso pochi e semplici step, sarà possibile configurare la camicia in tutti i suoi aspetti, dalla scelta del tessuto e degli elementi che la compongono, fino all'inserimento delle misure.",
    titolo12: "Processo d'Acquisto",
    sottotitolo12:
      "L'applicazione è in grado di gestire tutte le fasi del processo d'acquisto, dalla gestione del carrello, e quindi prodotti e codici sconto, fino alla gestione della spedizione e del pagamento.",
    titolo13: "Creazione Account",
    sottotitolo13:
      "L'applicazione prevede la possibilità di creare un profilo utente personale che permetterà all'utente stesso di memorizzare le proprie misure e i propri acquisti.",
    chisiamo: {
      sotto1:
        "We do our best to offer our customers the freedom to choose their own style in absolute freedom, always sure to receive a high quality product.",
      p1: "Sartorie Italiane® is a brand of the Globalapulia Srl group.",
      p2:
        "Combining tradition and innovation, SartorieItaliane.it is born, an advanced online sales portal for tailored shirts. With a clear and effective layout, you can buy high quality tailor-made products, entirely made to measure, delivered directly to your home. Thanks to a quick and intuitive configuration system, the user has the possibility to create his own shirt, customizing it down to the smallest details: from the collar, to the thread of the buttons up to the cuffs; you can also add your initials wherever you want. Play with as many combinations as possible.",
      p3:
        "We will keep your design and model in our system so you can, in the future, rearrange exactly the same type of cut or fabric if so you wish. You will then be able to indicate any changes you desire to satisfy your wishes in detail.",
      p4:
        "We aim to get the line and cut that best fit your shape and taste, providing you with a wide range of customizations and the great experience of the craftsmen we work with.",
      p5:
        "All this is made possible by the collaboration with prestigious artisan laboratories, an expression of Italian excellence, where skill and passion have been passed down from generation to generation.",
    },
    nostritessuti: {
      sotto1:
        "Choose from a wide range of fabrics, all strictly produced by the most prestigious Italian textile brands.",
      p1:
        "For your shirt you can choose different fabrics, finishes and color combinations. Our offer of fabrics and designs is constantly evolving, following the progress and new trends in fashion and the textile industry.",
      p2:
        "The history of the Canclini Tessile began in 1925 when it was born as a silk industry in the Como district. In the 1960’s, the first generational change took place: the experience gained in the production of a noble and high-grade product such as silk induced the family to leave the silk sector in the 1960s and to turn to the cotton yarn. This choice was fruitful, as in the following years, fabric sales extend to the overseas markets, in Germany and France. At the end of the eighties, the company’s business gained momentum from the purchase of state-of-the-art machinery.Today, the company is one of the leading brands in the field of quality fabrics for shirts.",
      p3:
        "Cotonificio Albini SpA is an Italian company based in Albino (province of Bergamo) which operates since 1876 in the textile sector, producing high-quality shirt fabrics. In 1992, two British brands, “Thomas Mason” and “David & John Anderson”, were acquired, together with their historical archive of over 600 volumes of inestimable value. The expansion process was continued with new production sites and the purchase of the “Albiate 1830” brand.",
      p4:
        "Creativity, innovation and experimentation: these are the coordinates that characterize the research journey of which Albiate 1830 is the absolute protagonist. Thanks to its dynamic and pioneering identity, it is able to reveal new itineraries and pristine destinations for contemporary casualwear, in a journey of exploration that reinforces the link with emerging fashion trends, without ever losing sight of the exceptional manufacturing tradition that gives almost two centuries is the heart of the brand. An essence that drives Albiate 1830’s Italian Textile Explorers to create fabrics of unparalleled style and quality. With its sports bases, eccentric prints, original jacquards and shades of a thousand shades, Albiate 1830 is aimed at a young public with a rebellious soul, confirming itself as the undisputed leader in luxury streetwear. Albiate 1830 is much more than a brand: it is the expression of a unique creative research, in which exclusive raw materials, iconic details and experimentation give life to an unexpected and surprising journey of style.",
      p5:
        "Founded in Lancashire in 1796 by entrepreneur Thomas Mason, the brand was a reference point in the world of English shirts for the elegance and quality of its products. Acquired by the Albini Group in 1992, Thomas Mason reinterprets his rich heritage in a modern and original way, through double twisted fabrics created with the fine linen of Normandy and the precious Egyptian cotton Giza 45 and Giza 87. Our shirts are made exclusively in Italy with fabrics produced in Italy.",
        p6: "TEXTA Srl has been established in 2014 with the primarely target to develop plain and printed fabrics with high quality level and style values. Today TEXTA shows its leadership in the development of artistic proposals of printed and plain fabrics for high-end shirting and apparel garments. TEXTA is a modern and dynamic player striving for style, excellence and innovation. TEXTA has a vertical production system enabling a full quality screening of its vital processes obtained through a highly skilled team of professionals. TEXTA outlines the true value of the “Made in Italy” concept. The selection of raw materials is the milestone of our overall strategy as well as the first step towards the offer of high quality standard at each single stage of our production process.",

      ande:
        "This Jersey grey fabric is a perfect choice to make a casual elegant shirt. Combine with your blue jeans and a casual jacket. This jersey fabric made of 90/2 melange yarn.",
      squire:
        "Rigorous poplin constructed with 62 threads 90/2 and 30 beats 60/1. It offers a vast assortment of both classic and patterned stripes and checks.",
      piave:
        "Double twisted fabric in 90/2, studied in different weaves with a wide range of colors and patterns.",
      rock: "Print on ovo poplin base.",
      oxford70:
        "It is a fabric created with very fine yarns, which allow the dotted effect typical of Oxford, obtained in a sophisticated and discreet manner. Durable and shiny, Oxford 70 reveals a wonderful density and a soft touch and is perfect for both the office and casual shirts.",
      twill70:
        "Twill 70 fabrics are the perfect mix between elegance and versatility. The diagonal structure gives the twill fabrics a lustrous look, but also a soft handle and a feeling of comfort. The definition of the designs combined with their simplicity makes Twill 70 the ideal fabric for the perfect urban gentleman.",
      piquet70:
        "It represents the essence of Albini structured fabrics. The high density of the warp and weft, and the proficient combination of different structures, create new three-dimensional effects along with sophisticated plays of light and colour.",
      saharaBright:
        "Albini Group only selects the very best European linen to create fresh and natural fabrics with exceptional properties: maximum durability, high moisture absorption capacity, insulating and temperature regulating properties along with hypoallergenic characteristics.",
      sJames:
        "It is a richly constructed 100/2 old English poplin, exceptional for its body and character.",
      duke:
        "There are many differences that make the Duke fabric so utterly unique. This classic and versatile fabric is made with superior extra-long staple cotton, making it highly resistant to wear and tear and washing, but also soft, lustrous and easy to care for. A wide assortment of structures creates new plays of light and three-dimensional effects.",
      piumino:
        "An enchanting lightweight sensation: Piumino is made from single superfine yarns in superior Giza 87 Egyptian cotton. A lightweight yet compact fabric, with a distinct Italian flair boasting brilliance, strength and elegance. Thanks to its silky feel, it adapts to the body as if it were a second skin.",
      bruges:
        "The combination of the finest long staple cotton and the most superior quality European linen generates sophisticated linen blend fabrics that combine the ultimate characteristics of these two natural fibres: the pleasant feel and hard-wearing features typical of cotton with the coolness and creativity of linen.",
      superPiumino:
        "It is a silky and compact fabric made from single and ultrafine yarns in superior Giza 87 Egyptian cotton. Thanks to its rich base, Super Piumino is available with different structures to adapt to a different elegant or informal style every day.",
      silver:
        "Silver, the 100/2 colour-woven poplin, whose name is synonymous with preciousness, brilliant whiteness and purity. Silver epitomises the classic striped and checked English shirt, with its exceptional handle, colour vibrancy and durability.",
      comfort:
        "It is a compact and bright stretch fabric which guarantees perfect wearability for a dynamic and contemporary lifestyle and an elasticity that remains unchanged over time. In the weft, the elastomer yarn is covered with cotton, for a natural feel on the skin.",
      portland:
        "It is a refined colour-woven poplin in superfine 120/2 warp and weft, which is defined by fine elegance, colour definition and timeless appeal, together with an exceptional soft yet crisp handle with surprising longevity.",
      victoriaFlam:
        "It is a denim fabric characterized by interesting slub, which gives the fabric a vintage and natural look.",
      chambray:
        "Lightweight and breathable, Chambray is a fabric with a soft and cool handle making it ideal for the summer season. The highly proficient use of slub yarns enhances its striking and casual look. Chambray is exceptionally versatile, yet always refined, and adapts perfectly whatever the occasion.",
      buckingham:
        "A classic of elegance and fineness, Buckingham is a poplin in 140/2 count and is named after the grand and official residence of Her Majesty Queen Elizabeth II.",
      zephir:
        "Light and delicate as the Zephyr west wind, this superfine gingham cotton fabric has an equal number of warp and weft threads that give an harmonious appearance.",
      royalOxford:
        "Often imitated, but never bettered, the Royal Oxford is a beautiful fabric with a distinctive, significant texture. The combination of colour, brilliance, two-fold yarns and the long tradition of the Oxford, all contribute to the spirit of this exceptional fabric which is also extremely versatile, adaptable to both informal and formal occasions.",
      journey:
        "The Journey collection by Thomas Mason offers innovative fabrics ideal for a shirt that looks fresh and smooth all day long, even after being packed at length in a suitcase. Thanks to an innovative finishing, these fabrics are resistant to creasing and crumpling, and require no special care to maintain a fresh appearance.",
      balmoral:
        "It is a precious fabric which combines the softness and the warmth of the cashmere, with the transpiration and comfort typical of the cotton. The result is a light fabric with a soft and silky touch, ideal for a noble and elegant casual shirt, beautiful to wear, simple to care, and a truly unique comfort.",
    },
    nostriartigiani: {
      sotto1:
        "Our shirts are produced exclusively in Italy by skilled artisans, in tailoring laboratories among the most prestigious and appreciated in the world.",
      p1:
        "Our expert tailors, true masters whose knowledge and skill has been handed down from generation to generation, deal with everything related to packaging, thus ensuring maximum precision in every phase of the manufacturing process, coupling the fabrics, creating the highest level of stitching destined to last over time, using only top quality Italian yarns, accessories and components.",
      p2:
        "Every stage of processing, from design control to fabric cutting, to the seaming of every detail is subjected to strict quality controls, combining Italian craftsmanship with the most modern and precise production technologies.",
      p3: "… the details that make the difference:",
      t4: "Needle and thread",
      p4:
        "Like any other detail of our shirts, even the button holes and thread that secures the buttons, can be customized freely, choosing from a wide range of colors.",
      t5: "Buttons",
      p5:
        "Our buttons are applied with the Ascolite® method: a hot welded elastic wire wraps the foot of the button giving it a great resistance.",
      t6: "Stitching",
      p6:
        "The seam of our shirts is handmade. With a 5mm single needle seam, robust and high quality, it has a far greater resistance over time than the industrial ones.",
      t7: "Fly",
      p7:
        "The fly is a triangle of fabric sewn from each side between the front and the back of the shirt, in order to reinforce stitching and avoid tearing. It is present in all our designs and can be customized on request with figures or logos.",
      t8: "Thickness of Collar and Cuffs",
      p8:
        "Our artisans will wrap their shirts using medium thick collars and cuffs. Custom Thicknesses of Different Hardness (Ultra Lightweight, Lightweight, Medium, Heavy, Ultra Heavy) are always available without any cost increase at customer’s request. Perhaps not everyone knows it, or they have never paid too much attention to it, but the parts of the shirt, that is, all the tailoring elements needed for its implementation, have a very precise name that has remained unaltered over time.",
      p9:
        "As with industrial production and fashion evolution some of these elements have disappeared, still today, according to the tradition of handcrafted shirts, a quality shirt must conatain all these parts. In order to understand the complexity of the shirtwork, we try to separate and recognize, one by one, the most important parts of the shirt, and to recognize in detail what determines the quality:",
      t10: "Front",
      p10:
        "This is exactly the front of a shirt, or rather of the two parts that compose it, where the buttons and, sometimes, the pockets are applied;",
      t11: "Pocket",
      p11:
        "The pocket is usually sewn on the left side of the front. It is a detail that usually appears in sports shirts, especially with button down neck, and in work shirts, where it has utility as well as decorative purpose;",
      t12: "Cannoncino",
      p12:
        "This is the band on which the button holes are cut out.The “sewn” stitched cannon is typical of sports shirts, while in stylish and classic shirts it does not appear;",
      t13: "Cuff",
      p13:
        "The shape of the cuff varies according to the shirt type. In classic models, the cuff has beveled or rounded tips and the sleeve at the joining point has some small bends. In sports models, the cuff is straight;",
      t14: "Hips",
      p14:
        "From the seams along the hips, it is easy to deduce the quality of a shirt’s fabrication. Higher the density of the stitches higher the quality of the shirt: at least 8/9 points per cm mean an excellent shirt;",
      t15: "Hem",
      p15:
        "This is the finishing on the bottom of the shirt, which varies according to the model and the manufacturer;",
      t16: "Buttons",
      p16:
        "The buttons are also a sign of distinction for a quality shirt. In elegant shirts, they must have a thickness of at least 2/3 mm, preferably with mother-of-pearl effect. Behind the cannon should be sewn at least one pair of spare buttons;",
      t17: "Armholes",
      p17: "t is the point where the sleeve is inserted;",
      t18: "Carrè",
      p18:
        "It’s the part of the shirt that covers the shoulders. In quality shirts, the carrè consists of a separate part from the rest of the shirt, in double fabric and vertically cut;",
      t19: "Box pleat and back",
      p19:
        "The main pleat behind the shirt, worked differently according to the model. It can be smooth, with central pleat, with two side folds or with pinces for slim shirts that follow the shape of the body;",
      t20: "Collar",
      p20:
        "The collar also stands out according to the pattern and style of the shirt. The most popular collars are Italian, French and button down. In elegant shirts the collar is kept rigid by the insertion of metal or plastic clamps.",
      t21: "Fly",
      p21:
        "The fly is a triangle of fabric sewn from each side between the front and the back of the shirt, in order to reinforce the stitching and avoid tearing.",
    },
  },
  contact: {
    titolo: "Send us a message",
    sottotitolo:
      "Contact us to request a quote or simply for questions or suggestions. We will get back to you as soon as possible.",
    nome: "Your name",
    telefono: "Your telephone number",
    email: "Your email",
    messaggio: "Leave a message",
    invia: "Send the message",
  },
  header: {
    account: "Account",
    modificapassword: "Change your Password",
    logout: "Logout",
    wishlist: "Wishlist",
    shopping: "Shopping Bag",
    loggedOut: "You are no longer logged in.",
    loggedOutError: "An error has occurred:",
    cambiaPasswordSuccess:
      "We will send you an email containing a unique link to reset your password.",
    cambiaPasswordError: "The following error has occurred:",
  },
  coloriTip: {
    grigio: "Grey",
    blu: "Blue",
    nero: "Black",
    marrone: "Brown",
    verde: "Green",
    bianco: "White",
    viola: "Violet",
    arancione: "Orange",
    beige: "Beige",
    giallo: "Yellow",
    rosa: "Pink",
    rosso: "Red",
  },
  footer: {
    account: "Account",
    login: "Login",
    logout: "Logout",
    cookie: "Cookie Policy",
    privacy: "Privacy Policy",
    termini: "Terms and Conditions",
    recesso: "Right of Withdrawal",
    contattaci: "Contact Us",
    partita: "P.IVA",
    about: "About us",
    ourfabrics: "Our fabrics",
    ourartisans: "Our artisans",
    famiglie: "Fabric family description",
  },
  dettaglio: {
    descrizione: "Fabric description",
    comeMisure: "How to take measurements",
    spedizione: "Shipping and return",
  },
  navigazione: {
    lang: "en",
    tessuto: "Fabric",
    scegliTessuto: "Select the fabric",
    inOfferta: "On sale",
    nonDisponibile: "Not available",
    personalizza: "Customize",
    personalizzazione: "Customization",
    polsinoGemelli: "Cufflinks cuff",
    polsinoDU: "Double use cuffs",
    infoDU: "Suitable for cufflinks too",
    contrastoColletto: "Apply to collar",
    contrastoPolsino: "Apply to cuffs",
    contrastoChiusura: "Apply to button placket",
    chiusura: "Closing",
    colletto: "Collar",
    polsino: "Cuffs",
    taschino: "Pocket",
    pinces: "Back",
    asole: "Buttonholes",
    bottoni: "Buttons",
    fili: "Thread",
    contrasto: "Contrasts",
    loading: "Loading...",
    mascherina: "Mask",
    maniche: "Sleeves",
    info1: "Slim Fit only",
    iniziali: {
      iniziali: "Monogram",
      inserisciIniziali: "Insert your monogram",
      tueIniziali: "Enter your monogram",
      stile: "Monogram style",
      colore: "Monogram color",
      bianco: "White",
      blu: "Blue",
      celeste: "Light blue",
      grigio: "Grey",
      nero: "Black",
      rosso: "Red",
      verde: "Green",
      arancione: "Orange",
      rosa: "Pink",
      posizione: "Position",
      sopra: "Up",
      centro: "Middle",
      sotto: "Wrist",
      aggiungiIniziali: "Add initials",
      stile1: "style",
      colore1: "color",
      posizione1: "position",
    },
    misure: {
      uomo: "Man",
      donna: "Woman",
      bambino: "Kid",
      metodoTitolo: "Select the mode you prefer",
      metodoTaglia: "Measures from standard sizes",
      metodoCorpo: "Measure your body",
      metodoCamicia: "Measure a shirt that suits you",
      metodoInvia: "Send us your shirt to measure it",
      metodoModifica: "Modification",
      metodoInformazioni: "Informations",
      selezionaTaglia: "Select your size",
      selezionaVestibilita: "Select your fit...",
      selezionaTagliaBambino: "Kid size",
      anni: "Years",
      anno: "Year",
      polo: "Polo Style",
      vestito: "Dress",
      misure: "Measurements",
      tueMisure: "Your measurements",
      salva: "Save your measurements",
      errore: "An error has occurred",
      successo: "Measures successfully saved",
      Braccia: "Arms",
      Collo: "Neck",
      Girovita: "Waist",
      Polso: "Wrist",
      Spalle: "Shoulders",
      Torace: "Chest",
      Lunghezza: "Lenght",
      vuoiAiuto: "Would you like some assistance with your measurements?",
      provaSelezionare: "Try selecting your size",
      successoCarica: "Measures successfully loaded.",
      seleziona: "Select...",
      xs: "XS (Neck 38)",
      s: "S (Neck 38)",
      m: "M (Neck 39/40)",
      l: "L (Neck 41/42)",
      xl: "XL (Neck 43/44)",
      xxl: "XXL (Neck 45/46)",
      xxxl: "XXXL (Neck 47/48)",
      oppure: "or",
      stoCaricando: "Loading...",
      carica: "Load your measurements",
      inviaTitolo: "Mail a Shirt to Measure",
      inviaParag1:
        "Want to copy the fit of a shirt you already have, but aren’t sure how to measure it properly?",
      inviaParag2:
        "Mail us your shirt and our experts will measure it and create a special saved size in your account for you. *Note that our measuring causes no damage to the shirt and we will return it together with your purchase.",
      inviaParag3: "Send to:",
      inviaTitolo2: "When sending, be sure to include:",
      inviaLista1: "The shirt you want us to measure",
      inviaLista2: "Your name",
      inviaLista3: "Phone number",
      inviaLista4: "Email address",
      inviaLista5: "Return shipping address",
      inviaLista6: "Any special instructions",
      infoTipoMisure: "Measure mode:",
      infoTipoMisure0: "Measures from standard sizes",
      infoTipoMisure1: "Measurements taken on your body",
      infoTipoMisure2: "Measurements taken from your shirt",
      infoTipoMisure3: "Will send a shirt to have it measured",
      infoTagliaSelezionata: "Chosen size:",
      infoVestibilita: "Fit",

      tabellaTaglie: "Size table",
      slimDonna: "WOMAN SLIM SHIRT",
      vestitoslimDonna: "WOMAN SLIM DRESS",
      regularDonna: "WOMAN REGULAR SHIRT",
      vestitoregularDonna: "WOMAN REGULAR DRESS",
      boyfriendDonna: "WOMAN BOY-FRIEND SHIRT",
      poloDonna: "WOMAN POLO-STYLE SHIRT",
      camiciaUomo: "MAN SLIM, REGULAR AND POLO-STYLE SHIRT",
      camiciaBambino: "KID SLIM, REGULAR AND POLO STYLE SHIRT",
      small: "SMALL",
      medium: "MEDIUM",
      large: "LARGE",
      extralarge: "EXTRALARGE",
      Vita: "WAIST",
      Manica: "ARMS",
      statura: "HEIGHT",
    },
  },
  barraAcquisto: {
    lang: "en",
    camicia: "Shirt",
    nonDisponibile: "Fabric not available.",
    tooltip: "Add this shirt to cart",
    carrello: "Add to cart",
    tessuto: "Fabric ",
    di: " of ",
    aggiunta: "Shirt added to cart.",
    aggiunta1: "Shirt successfully added to cart.",
    aggiunta2: "What do you want to do now??",
    continua: "Continue your shopping",
    vai: "Go to shopping bag",
    wishlist: {
      lang: "en",
      nuovoPrezzo: "New price",
      nonDisponibile: "Caution. Fabric not available!",
      wishlist: "Wishlist",
      vuota: "Your Wishlist is empty!",
      Camicia: "Shirt",
      tooltip: "Add shirt to wishlist",
      successo: "Shirt succesfully added to wishlist.",
      rimossa: "Shirt succesfully removed from wishlist.",
      Prodotto: "Product",
      Prezzo: "Price",
      Descrizione: "Description",
      Tessuto: "Fabric",
      Disegno: "Drawing",
      Colletto: "Collar",
      Italiano: "Italian",
      "Italiano Classico": "Classic Italian",
      "Francese Classico": "Classic French",
      "Francese Semiaperto": "Semi French",
      "Francese Semiaperto due bottoni": "Semi French two buttons",
      "Botton Down Classico": "Button Down",
      "Botton Down Alto due bottoni": "Button Down two buttons",
      Diplomatico: "Smoking",
      Coreano: "Korean",
      "Polsino Arrotondato": "Rounded Cuff",
      "Polsino Smussato": "Beveled Cuff",
      "Polsino Gemelli": "Cufflinks Cuff",
      "Manica Corta": "Short Sleeve",
      Chiusura: "Closing",
      "Faldoncino Interno non Impunturato": "French",
      "Faldoncino Esterno Impunturato": "Standard",
      "Finta Doppia e bottoni nascosti": "Hidden",
      "Nessun Taschino": "No Pockets",
      "Taschino Arrotondato": "Rounded Pocket",
      "Taschino Smussato": "Bevelled Pocket",
      "Due Taschini Arrotondati": "Two Rounded Pockets",
      "Due Taschini Smussati": "Two Bevelled Pockets",
      "Dietro Liscio": "No Darts",
      "Dietro con Doppie Pinces": "Double Darts",
      "Dietro con Doppia Piega": "Double Folds",
      "Asola Bianca": "White Slot",
      "Asola Blu": "Blue Slot",
      "Asola Celeste": "Light blue Slot",
      "Asola Grigia": "Gray Slot",
      "Asola Nera": "Black Slot",
      "Asola Rossa": "Red Slot",
      "Bottone Bianco": "White Button",
      "Bottone Blu": "Blue Button",
      "Bottone Celeste": "Light blue Button",
      "Bottone Grigio": "Gray Button",
      "Bottone Nero": "Black Button",
      "Bottone Rosso": "Red Button",
      "Filo Bianco": "White Thread",
      "Filo Blu": "Blue Thread",
      "Filo Celeste": "Light blue Thread",
      "Filo Grigio": "Gray Thread",
      "Filo Nero": "Black Thread",
      "Filo Rosso": "Red Thread",
      Contrasto: "Contrast",
      contrastoPolsino: "Cuffs",
      contrastoColletto: "Collar",
      contrastoChiusura: "Closure",
      Stile: "Style",
      Iniziali: "Monogram",
      iniStile: "Style",
      iniColore: "Color",
      iniPosizione: "Position",
      white: "White",
      slateblue: "Blue",
      lightsteelblue: "Light blue",
      lightgray: "Gray",
      black: "Black",
      crimson: "Red",
      posizione: "Position",
      sopra: "Up",
      centro: "Middle",
      sotto: "Down",
      rimuovi: "Remove from Wishlist",
      importa: "Import in the configurator",
      torna: "Back to the configurator",
    },
  },
  tutorial: {
    braccia: {
      header: "Arms",
      titolo: "Arm lenght",
      descrizione:
        "Measure the sleeve from the shoulder to the wrist holding your arm bent at 90°. For accurate measurement, it is necessary to measure from the shoulder to the elbow and proceed to the end of the wrist, where it joins the hand.",
    },
    bracciaCamicia: {
      header: "Arms",
      titolo: "Arm lenght",
      descrizione:
        "Measure the sleeve from seam of the shoulder to the end of the cuff. For accurate measurement, it is necessary to keep the sleeve flat on a flat surface.",
    },
    collo: {
      header: "Neck",
      titolo: "Neck size",
      descrizione:
        "Measure immediately beneath the Adam's apple, sliding the measuring tape around the neck to take the circumference. It is important to insert a finger between the measuring tape and the neck to ensure a comfortable grip.",
    },
    colloCamicia: {
      header: "Neck",
      titolo: "Neck size",
      descrizione:
        "Measure the linear distance between the collar button and the corresponding buttonhole, keeping the collar wide open and lying flat on a flat surface.",
    },
    girovita: {
      header: "Waist",
      titolo: "Waist size",
      descrizione:
        "Measure the waistline at the widest point, at the height of the navel. If you have wide hips it is advisable to take the measurement slightly lower, to measure them in total. For a proper fit, you must adhere to the waistline, always leaving a finger of tolerance between the body and the measuring tape, so that you can sit comfortably. It is important to take the precise measurement, keeping the measuring tape adherent to the body: fit will be given by the style chosen while designigng the shirt.",
    },
    girovitaCamicia: {
      header: "Waist",
      titolo: "Waist size",
      descrizione:
        "Measure the waistline at the widest point at the navel, between the second and third buttons from the bottom, keeping the shirt flat on a flat surface. If you have wide hips, it is advisable to take the measurement slightly lower , to include them in the total measurement.",
    },
    polso: {
      header: "Wrist",
      titolo: "Wrist size",
      descrizione:
        "Measure the wrist circumference at the wrist joint. For a proper fit you have to make the measuring tape adhere to the wrist while retaining a finger of tolerance between the wrist and the measuring tape.",
    },
    polsoCamicia: {
      header: "Wrist",
      titolo: "Wrist size",
      descrizione:
        "Measure the linear distance between the cuff button and the corresponding buttonhole, keeping the cuff open and lying flat on a flat surface.",
    },
    spalle: {
      header: "Shoulders",
      titolo: "Shoulders size",
      descrizione: "Measure the shoulders width at the widest point.",
    },
    spalleCamicia: {
      header: "Shoulders",
      titolo: "Shoulders size",
      descrizione:
        "Measure the shoulder width at the widest point, from the seam of the left arm to the seam of the right arm, keeping the shirt flat on a flat surface.",
    },
    torace: {
      header: "Chest",
      titolo: "Chest size",
      descrizione:
        "Measure the chest circumference at nipple height. For a proper fit, you must adhere the measuring tape to the chest, always holding a finger of tolerance between the body and the measuring tape so that you can move freely.",
    },
    toraceCamicia: {
      header: "Chest",
      titolo: "Chest size",
      descrizione:
        "Measure the circumference of the chest at chest height, between the second and third buttons from above. Measure the linear distance between the button and the opposite corresponding buttonhole.",
    },
    lunghezza: {
      header: "Length",
      titolo: "Length",
      descrizione:
        "Tilt your head forward and measure from the center of the back base of the neck, at the most protruding bone, down to 3/4 of the buttocks for a classic length. If you wear a shirt, measure the back side from the center of the collar seam to the bottom hem.",
    },
    lunghezzaCamicia: {
      header: "Length",
      titolo: "Length",
      descrizione:
        "Keeping the shirt flat on a flat surface, measure from the center of the back base of the neck, at the center of the seam between the shirt and collar, to the bottom hem.",
    },
  },
  contatti: {
    richiesto: "Required field",
    emailInvalida: "Invalid email address",
    titolo: "We love to talk with you",
    successo: "Thank you. Your message has been successfully sent.",
    errore: "Caution. An error has occurred.",
    nome: "Name *",
    email: "Email *",
    oggetto: "Subject *",
    messaggio: "Message *",
    attendere: "Wait...",
    invia: "Send",
    testo01:
      "EVERY ORDER REQUIRES AT LEAST 20 WORKING DAYS BEFORE SHIPPING. WE APOLOGIZE FOR THIS ANNOYANCE BUT YOUR SHIRT WILL NOT BE LIKE ALL THE OTHERS.",
    testo02:
      "Sartoria Turrisi is in Ceglie Messapica, a welcoming town in the Province of Brindisi, in the south of Puglia.",
    testo03:
      "Since 1970 our strong sartorial tradition has been handed down from father to son: we are a small artisan company that operates with passion at every stage of production, which is carried out exclusively within our tailor's shop by highly specialized Italian artisans.",
    testo04:
      'The choice of fabrics and accessories of the highest quality allow us to offer the excellence of the authentic "Made in Italy".',
    testo05:
      "Our goal is to satisfy our client in every aspect, putting our long experience at his disposal.",
    testo06:
      "Today, it is possible to confuse a sculptor with a tailor, because both create shapes.",
    testo07: "KARL KRAUS",
    testo08: "FEEL OUR FABRIC SELECTION WITH YOUR HANDS!",
    testo09: "ORDER YOUR FREE KIT NOW!",
  },
  pagina404: {
    errore: "Error 404",
    nonEsiste: "The page you are looking for does not exist!",
    torna: "Back to configurator",
  },
};
